<template>
  <div class="deal-tab">
    template deal tab
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

import {
  getId,
  getShareLink,
  getCategoryCode,
  getLinkImageDeal,
  getTitleDeal,
  getSubtitleDeal,
  getPriceDeal,
  getIsTransfer,
  getBaggage,
  getType,
  getTabs,
  getTitleTab,
  getLinkTab,
  getLink,
  getDestCode,
  getPackageCategory,
  getTitleRemark,
} from '@/utils/bonauf/controllerCommon';

const { VUE_APP_BONAUF_DOMAIN_MOBILE, VUE_APP_STAGING_DOMAIN } = process.env;

export default {
  name: 'TemplateDealTab',
  mixins: [imageUrlMixin],
  data() {
    return {
      activeTabIndex: 0,
      openShareModal: false,
      shareContent: null,
    };
  },
  computed: {
    ...mapGetters({
      testMode: 'GET_TEST_MODE',
      filteredDeals: 'GET_FILTERED_APP_DEALS',
      activeDeal: 'GET_SPECIFIC_APP_DEAL',
      getDestName: 'GET_DEST_NAME',
      getDestinationName: 'GET_DEST_NAME',
      getCategoryName: 'GET_CATEGORY_NAME',
    }),
    hotelImage() {
      return getLinkImageDeal(this.activeDeal);
    },
    hotelSubTitle() {
      return getSubtitleDeal(this.activeDeal);
    },
    hotelTitle() {
      return getTitleDeal(this.activeDeal);
    },
    hotelDest() {
      return this.getDestName(getDestCode(this.activeDeal));
    },
    isTransfer() {
      return getIsTransfer(this.activeDeal);
    },
    baggage() {
      return getBaggage(this.activeDeal);
    },
    type() {
      return getType(this.activeDeal);
    },
    price() {
      return getPriceDeal(this.activeDeal);
    },
    tabs() {
      return getTabs(this.activeDeal);
    },
    titleTab() {
      return getTitleTab(this.activeTabIndex, this.activeDeal);
    },
    linkTab() {
      return getLinkTab(this.activeTabIndex, this.activeDeal);
    },
    packageCategory() {
      return getPackageCategory(this.activeDeal);
    },
    titleRemark() {
      return getTitleRemark(this.activeDeal);
    },
    hasTitleRemark() {
      return this.titleRemark !== '';
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'deal-tab');
    await this.fetchAssetsData();
  },
  mounted() {
  },
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
    async fetchAssetsData() {
      const { query } = this.$route,
        dealId = query.id;
      await this.$store.dispatch('FETCH_APP_DEAL_PACKAGES');
      // let pickDeal = this.filteredDeals.find((deal) => deal.id === dealId);
      // if (pickDeal === undefined) {
      //   pickDeal = await this.$store.dispatch('FETCH_APP_DEAL_SPECIFIC', { id: dealId });
      // }
      await this.$store.dispatch('FETCH_APP_DEAL_SPECIFIC', { id: dealId });
    },
    isActive(pIdx) {
      return this.activeTabIndex === pIdx;
    },
    activeTab(pIdx) {

      window.addEventListener('message', this.resizeIframe);

      this.activeTabIndex = pIdx;
    },
    gotoProduct() {
      const { query } = this.$route,
        dealId = query.id;
      window.sessionStorage.setItem('dealId', dealId);
      this.$router.push({ path: getLink(this.activeDeal) });
    },
    gotoDealFilter() {
      this.$router.push({ path: '/deal-filter/app?channel=App' });
    },
    showModal(pDeal) {
      // console.log(pDeal);
      let link = getShareLink(pDeal);
      link = link.replace(VUE_APP_BONAUF_DOMAIN_MOBILE, '');
      link = link.replace(VUE_APP_STAGING_DOMAIN, '');
      this.shareContent = {
        id: getId(pDeal),
        link: encodeURI(`${link}&text=מצאתי דיל בבוא נעוף שיכול לעניין אותך, בוא נעוף ביחד`),
        category: this.getCategoryName(getCategoryCode(pDeal)),
        dest: this.getDestinationName(getDestCode(pDeal)),
        hotel: getTitleDeal(pDeal),
        image: getLinkImageDeal(pDeal),
        country: '',
      };
      this.openShareModal = true;
    },
  },
};
</script>

<style scoped>
  .price-text {
    cursor: pointer;
  }
</style>
