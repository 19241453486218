<template>
  <div class="apparea">
    <!----- header area start ----->
    <header-desktop />
    <!----- header area stop ----->
    <div class="body_warrper">
      <section class="kremlin-area kremlin-area2" :style="perspectiveBackground">
        <div class="container">
          <mobile-header-app :product="product" />
          <mobile-head-banner-app :stage="bookingStage" />
          <h2>פרטי הטיסה</h2>
        </div>
      </section>
      <!------ plaindetails area start ------->
      <section class="plaindetails-area">
        <div class="container">
          <flight-item-app :flight="activeFlight" :categoryName="categoryCode" type="booking" />
          <ticket-information v-if="category.code === 'sport_pack'" />
          <div class="p-3 mb-3 w-100 card" v-if="isOdysseySite">
            <internal-agent-info @updateInternalAgentInfo="changeAgentInfo" :currency="product.cc"
              :fullPrice="product.price" />
          </div>
          <h3 class="py-4">{{ $t('booking.guest-desc') }}</h3>
        </div>
      </section>
    </div>

    <section class="plainform-area">
      <div class="container">
        <user-list-room-app v-for="(room, idx) in rooms" :key="idx" :room="room" :index="idx"
          @updatedUser="updatingUser" />
        <h3>{{ $t('booking.payer-details') }}</h3>
      </div>
    </section>
    <section class="plainform-area2">
      <payer-information-app @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
    </section>

    <div class="container">
      <div class="new_booking_main_area dVacation-pack booking_mobile_part mt-3">
        <div class="booking_details_three">
          <div ref="checkTerms" class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5">
            <div class="booking_details_three bottom">
              <div class="price_details">
                <div class="price_details_main_body">
                  <div class="price_details_body">
                    <div class="terms_box">
                      <div class="form-check" v-if="!isOdysseySite">
                        <label :class="['form-check-label', 'pr-5', !checkedTerms ? 'danger' : '']">
                          <span class="ml-4">
                            <input type="checkbox" class="form-check-input" v-model="checkedTerms" />
                          </span>
                          {{  $t('booking.i-agree-to') }}
                          <!-- <a onclick="window.open(this.href, 'title', 'width=1200, height=600, top=300, left=100'); return false;" href="/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99"><strong>{{ $t('booking.agree-desc') }}</strong></a> -->
                          {{ $t('booking.i-agree-to') }}
                          <strong class="pointer" @click="() => showLinkInfo(`/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99`)">
                            {{ $t('booking.agree-desc') }}</strong>
                          {{ $t('booking.read-desc') }}
                        </label>
                      </div>
                      <div class="form-check" v-if="cancellationAllowed">
                        <label class="form-check-label pr-5">
                          <span class="ml-4">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              v-model="checkedCancellation"
                              :disabled="!cancellationAllowed"
                            />
                          </span>
                          {{ $t('booking.cancellation-agree') }} <strong @click="showLinkInfo('/תקנון_פליינג_סייף_(Flying_Safe)')">{{ $t('booking.flying-safe') }}</strong>
                        </label>
                      </div>
                      <!-- <div class="form-check" v-if="!isOdysseySite">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedPromotion"
                          />
                          {{ $t('booking.email-receive-agree') }}
                        </label>
                      </div> -->
                      <!-- <div class="form-check" v-if="!isOdysseySite">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedInsurance"
                          />
                          {{ $t('booking.telephone-provide-agree') }}
                        </label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="price_details" v-if="!isOdysseySite && isMarketer && !isFcAgentMarketerMode">
                <div class="price_details_heading">
                  <h3>{{ couponTitle }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body row">
                    <div class="col-lg-4 col-12">
                      <coupon
                        :userIndex="0"
                        :numOfPax="numOfPax"
                        :checkInDate="checkInDate"
                        :currency="currency"
                        :originalPrice="originalPrice"
                        :oldShekelPrice="oldShekelPrice"
                        :user="userList[0]"
                        :couponCodes="couponCodes"
                        :couponLabels="couponLabels"
                        @updateCouponList="updateCouponList"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!isOdysseySite && !isFcAgentMarketerMode && !isMarketer">
                <div class="price_details_heading">
                  <h3>{{ couponTitle }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body row">
                    <div class="col-lg-4 col-12">
                      <coupon
                        :userIndex="0"
                        :numOfPax="numOfPax"
                        :checkInDate="checkInDate"
                        :currency="currency"
                        :originalPrice="originalPrice"
                        :oldShekelPrice="oldShekelPrice"
                        :user="userList[0]"
                        :couponCodes="couponCodes"
                        :couponLabels="couponLabels"
                        @updateCouponList="updateCouponList"/>
                    </div>
                  </div>
                </div>
              </div> -->
              <!----- order area start ----->
              <section class="order-area total_payment">
                <div class="container">
                  <div class="order-list">
                    <ul>
                      <li>
                        <div class="h2 mb-0 align-self-center">
                          <span id="noticePrice" class="icon" @click="showNoticeTooltip()"><i
                              class="fas fa-exclamation-circle text-info"></i></span>
                        </div>
                      </li>
                      <li>
                        {{ $t('booking.total-payment') }}
                        <strong>{{ totalPriceRegular }}</strong>
                      </li>
                      <li>
                        סה”כ בשקלים
                        <strong>{{ totoalPriceShekels }}</strong>
                      </li>
                    </ul>
                  </div>
                  <div class="btn-box">
                    <button class="btn-primary" @click="BookingNow" :disabled="disableBook">
                      {{ $t('search-result.book-now') }}
                    </button>
                  </div>
                </div>
              </section>
              <!----- order area stop ----->
            </div>
          </div>

        </div>
      </div>
    </div>

    <footer-desktop :backLink="backLink"/>

    <b-modal id="countDownModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
          <h5>{{ maxSec }} {{ $t('booking.sec') }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{
          $t('booking.go-product-page')
        }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="
          gotoProductPage();
        ok();
        ">
          {{ $t('booking.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal id="actionModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      <template>
        <h5 :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t('booking.modal-delaying') }}</h5>
        <div class="actionContainer justify-content-between d-flex">
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToContinue')"><img :src="`${speedSizeDomain}/assets/img/next.jpg`"
                  class="col-6 icon_box" width="50" height="50" alt="next" />
                <h6 class="m-auto">{{ $t('booking.modal-continue') }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToCall')"><img :src="`${speedSizeDomain}/assets/img/phoneCall.png`"
                  class="col-6 icon_box " width="50" height="50" alt="phone-call" />
                <h6 class="m-auto">{{ $t('booking.modal-call', { phoneNumber: phoneNumber }) }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToBack')"><img :src="`${speedSizeDomain}/assets/img/previous.jpg`"
                  class="col-6 icon_box " width="50" height="50" alt="previous" />
                <h6 class="m-auto">{{ $t('booking.modal-back') }}</h6>
              </b-link>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="actionRetryDebit" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice-retry') }}</h3>
        </div>
      </template>
      <template>
        <payer-information @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
        <div :class="['action_buttons d-flex m-3', lang == 'he' ? 'float-left' : 'float-right']">
          <b-button class="m-3" block @click="retryBookWithNewPayerInfo"
            variant="outline-success">{{ $t("booking.retry-booking") }}</b-button>
          <!-- <b-button class="m-3" block @click="$bvModal.hide('actionRetryDebit')" variant="outline-success">{{$t("booking.close")}}</b-button> -->
        </div>
      </template>
    </b-modal>
    <b-modal id="extraLinkInfo" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :ok-only="true"
      :hide-footer="true">
      <template>
        <iframe id="linkInformation" title="Extra Inforamtion" :src="linkInfo">
        </iframe>
      </template>
    </b-modal>
    <b-tooltip target="noticePrice" ref="noticePrice" :title="$t('product-page.notice')" triggers="focus" variant="info">
      <h6>{{this.$t('product-page.notice')}}</h6>
      <span>{{ $t('booking.notice-final-exchange') }}</span>
    </b-tooltip>
  </div>
</template>

<script>
import { BModal, BButton, BLink } from 'bootstrap-vue';
import TemplateHotelFlightApp from './TemplateHotelFlightApp';


export default {
  components: {
    // BFormRating,
    // BToast,
    BButton,
    BModal,
    BLink,
    // BSpinner,
    HeaderDesktop: () => import('@/components/bonauf/atoms/HeaderDesktop'),
    MobileHeaderApp: () => import('@/components/booking/atoms/MobileHeaderApp'),
    MobileHeadBannerApp: () => import('@/components/booking/atoms/MobileHeadBannerApp'),
    FlightItemApp: () => import('@/components/productPage/atoms/FlightItemApp'),
    TicketInformation: () => import('@/components/booking/atoms/TicketInformation'),
    UserListRoomApp: () => import('@/components/booking/atoms/UserListRoomApp'),
    PayerInformationApp: () => import('@/components/booking/atoms/PayerInformationApp'),
    InternalAgentInfo: () => import('@/components/booking/InternalAgentInfo'),
    // Coupon: () => import('@/components/booking/atoms/Coupon'),
    FooterDesktop: () => import('@/components/bonauf/atoms/FooterDesktop'),
  },
  extends: TemplateHotelFlightApp,
  data() {
    return {
      backLink: '',
    }
  },
  created() {
    this.backLink = window.sessionStorage.getItem('marketerURL');
  }
};
</script>

<style lang="less" scoped>
  .bonauf-desktop {
    .apparea {
      max-width: 100%;

      .body_warrper {
        margin-top: -40px;

        .kremlin-area {
          padding: 60px 0 20px;
        }

        h2 {
          max-width: 780px;
          margin: auto;
        }
      }

      .plaindetails-area {
        h3 {
          max-width: 780px;
          margin: auto;
          font-size: 32px;
        }
      }
      .plaindetails-area::before {
        height: 165px;
      }

      .plainform-area {
        .container {
          max-width: 780px;
        }

        h3 {
          font-size: 32px;
        }
      }
      .plainform-area::before {
        height: 165px;
      }

      .new_booking_main_area {
        max-width: 780px;
        margin: auto;

        .terms_box {
          box-sizing: border-box;
          padding: 24px;
          border: 1px solid #c1c1c1;
          border-radius: 18px;
        }

        .order-area {
          .order-list {
            li {
              font-size: 28px;
              font-family: 'ploniregularaaa';

              strong {
                font-size: 30px;
                font-family: 'FbCoherentiSansBold';
              }
            }
          }
        }
        .order-area:before {
          background: #fff;
        }
      }
    }
  }
</style>
