<template>
  <div>
    <Breadcrumb type="area" :data="linkData" v-if="linkData && !isDesktopApp" />
    <Banner :banner="bannerImage" v-if="!isDesktopApp"/>
    <div :class="[!isDesktopApp ? 'my-5' : '', 'hotelplusflight booking']">
      <div class='px-0'>
        <Sidebar
          :data="hotels"
          :packageType="categoryName"
          :remarks="remarks"
          v-if="hotels.length && !isDesktopApp"
        />
        <div
          :class="[
            'filter-cont',
            hotels.length && !isDesktopApp ? 'col-md-9' : 'col-md-12',
            !isMobileApp ? 'dVacation-pack' : 'p-0',
          ]"
        >
          <router-view @setBookingStage="setBookingStage" />
        </div>
      </div>
    </div>
    <Footer v-if="!hideCondition"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bannerImageDestination from '@/utils/bannerImageDestination';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  mixins: [bannerImageDestination],
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    // Footer: () => import('@/components/HeaderFooter/Footer'),
    Footer: () => import('@/components/HeaderFooter/footer/FooterTheme0'),
  },
  data() {
    return {
      product: null,
      linkData: null,

      categoryName: '',
      hotels: [],

      bookingStage: 2,
      stageLabel: [
        '',
        'product-page.package-details',
        'product-page.payment-details',
        'product-page.Order-Completion',
      ],

      remarks: '',
      isMobileApp: false,
    };
  },
  computed: {
    ...mapGetters({
      categoryState: 'GET_CURRENT_CATEGORY',
      isLoading: 'GET_LOADING_STATE',
      isProcessingBooking: 'GET_PROCESSING_BOOKING_STATE',
      productState: 'GET_PRODUCT',
      destinationImages: 'GET_DESTINATION_IMAGES',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      typeChannel: 'GET_TYPE_CHANNEL',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',      
      isDesktopApp: 'GET_BONAUF_DESKTOP_APP',
    }),
    hideCondition() {
      const { bypassPaymentState, limitDealsState, isOdysseySite } = this;
      return (!isOdysseySite && (bypassPaymentState || this.device !== 'desktop' || this.isMobileApp)) || limitDealsState;
    },
    destinationImage() {
      if (!this.destinationImages) return '';
      const dest = this.product.destination_1;
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      const url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : '';
      return url;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  methods: {
    async fetchData() {
      const localProduction = JSON.parse(
        window.sessionStorage.getItem('production'),
      );
      this.category = this.categoryState || localProduction.category;
      this.product = this.productState || localProduction;
      this.$store.commit('SET_PRODUCT', this.product);
      this.categoryName = this.category.code;
      this.hotels = this.product.hotels;

      this.updateLabelWithLang();

      await this.fetchBannerImageByDestination();
      if (document.querySelector('.st-content')) { document.querySelector('.st-content').scrollTo(0, 0); }
    },
    setBookingStage(pStage) {
      this.bookingStage = pStage;
    },
    updateLabelWithLang() {
      let dest = '';
      if (this.product.category.code === 'Hotel_Only') {
        dest = `${this.product.activeHotel.destinationName} - ${this.product.activeHotel.name}`;
      } else {
        dest = (this.product.translations.flightDestinationName && this.product.translations.flightDestinationName[this.product.destination_1] ? this.product.translations.flightDestinationName[this.product.destination_1][this.lang] : null)
          || this.product.destination_1_name;
        this.remarks = this.product?.remarkTranslation?.[this.lang] || this.product.remark;
      }
      const searchQuery = this.product.category.code === 'sport_pack' ? `/search-result-sportpack?${new URLSearchParams(JSON.parse(window.sessionStorage.getItem('search-query'))).toString()}` : `/search-result?${new URLSearchParams(JSON.parse(window.sessionStorage.getItem('search-query'))).toString()}`;
      this.linkData = {
        text:
          this.product.category.name[this.lang]
          || this.product.category.name.en,
        dest,
        stage: this.$t(this.stageLabel[this.bookingStage]),
        searchQuery,
      };
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'booking');
    this.fetchData();
  },
  beforeMount() {
    this.isMobileApp = this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP && !this.$route.path.includes('oldapp');
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
};
document.body.classList.add('bookingPage');
</script>

<style>
.bookingPage .btnDomestic {display:none !important;}
</style>

<style scoped>

.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}

.bonauf-mobile .sideBar {
  display: none;
}

.bonauf-mobile .booking {
  margin-top: 0px !important;
}
.bonauf-mobile .booking > .container {
  display: flex;
}
.bonauf-mobile .booking > .container > .row {
  margin: auto;
  width: 100%;
}
.bonauf-mobile .booking > .container > .row > .filter-cont {
  max-width: 100% !important;
  flex: unset;
}

@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 10px;
  }
  .my-5.hotelplusflight {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 767.98px) {
  .hotelplusflight > .container {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .sideBar {
    display: none;
  }

  .dVacation-pack {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .filter-cont.dVacation-pack {
    flex: none !important;
  }
}
</style>
