<template>
  <div class="shareApp">
    <div class="linkButtons">
      <p>לפתיחת הדיל אנא לחץ כאן</p>
      <div class="btnBonauf">
        <a :href="urlBonauf" target="_blank" title="Open bonauf Deal">
          <img :src="`${speedSizeDomain}/assets/img/bonauf.png`" alt="bonauf" width="100" height="100"/>
        </a>
      </div>
    </div>
    <div class="appDownload">
      <div class="links">
        <a href="https://itunes.apple.com/il/app/bw-n-wp/id970349017?mt=8" class="appStor" target="_blank" title="" aria-label="נפתח בחלון חדש" tiplabel="true" style="box-shadow: none;">
          <img :src="`${speedSizeDomain}/assets/img/appStore.png`" width="100" height="100" alt="בו נעוף אפליקציה באפסטור">
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.flyingcarpet" class="googleplay" target="_blank" title="" aria-label="נפתח בחלון חדש" style="box-shadow: none;">
          <img :src="`${speedSizeDomain}/assets/img/googlePlay.png`" width="100" height="100" alt="בו נעוף אפליקציה גוגל פליי">
        </a>
      </div>
      <p>הורידו עכשיו חינם את האפליקציה מחנויות האפליקציות</p>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'ShareAppLinkPage',
  mixins: [imageUrlMixin],
  components: {},
  data() {
    return {
      urlBonauf: '',
    };
  },
  method: {

  },
  created() {
    const { id } = this.$route.query;
    this.urlBonauf = `bonauf://v=${id}`;
  },
};
</script>

<style>
  .shareApp {
    height: 100vh;
    background-color: #3baeb0;
  }

  .btnBonauf {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }

  .btnBonauf img {
    width: 200px;
  }

  .linkButtons {
    padding: 10% 30px 30px 30px;

  }

  .appDownload {
    margin-top: 50px;
  }

  .appDownload p, .linkButtons p {
    text-align: center;
    color: #fff;
    font-size: 20px;
  }

  .links {
    justify-content: center;
    display: flex;
  }

  .links a {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 2px solid #fff;
    border-radius: 5px;
  }

  .appDownload img {
    max-width: 100%;
  }

  .googleplay {
    margin-right: 10px;
  }

</style>
