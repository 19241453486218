<template>
  <div class="apparea deal-tab">
    <header class="header-area">
        <div class="header">
            <div class="container">
              <figure class="logo bonauf-app-logo" @click="gotoHome">
                <img :src="`${speedSizeDomain}/assets/img/app/logo2.png`" alt="#" title="" />
              </figure>
              <button class="btn-close pr-2" @click="gotoDealFilter">
                <i class="fa-solid fa-chevron-right ml-5"></i>
              </button>
              <div class="header-text">
                <h3>{{hotelDest}}</h3>
                <span>{{hotelSubTitle}}</span>
              </div>
            </div>
        </div>
        <div class="header-tab">
            <div class="container d-flex">
                <button class="btn">{{type}}</button>
                <button class="btn">{{baggage}}</button>
                <button v-if="isTransfer" class="btn">כולל העברות</button>
                <button v-if="hasTitleRemark" class="btn">{{ titleRemark }}</button>
                <div class="share-button" @click="() => showModal(activeDeal)" v-if="testMode">
                  <img :src="`${speedSizeDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
                </div>
            </div>
        </div>
    </header>
    <main class="body_warrper">
      <!-- banner area start -->
      <div v-if="hotelImage !== ''" class="banner-area">
        <img :src="hotelImage" alt="#" title="" />
      </div>
      <div class="tabarea">
        <div class="tab-heading">
            <div class="container d-flex">
              <button :class="['btn m-auto', {'active': isActive(index)}]" v-for="(tab, index) in tabs" :key="index" @click="activeTab(index)"><span>{{tab.title}}</span></button>
            </div>
        </div>
        <div class="tab-body">
          <div class="tab">
          <iframe :src="linkTab" />
          </div>
        </div>
      </div>
      <!-- price area start -->
      <div class="price-box">
          <div class="container">
              <div class="price-body">
                  <div class="price">
                      <p>החל מ-</p>
                      <h3>{{price}}</h3>
                      <p>לאדם</p>
                  </div>
                  <div class="price-text" @click="gotoProduct">
                      הזמן עכשיו
                  </div>
              </div>
          </div>
      </div>
      <!-- price area stop -->
      <b-modal id="share-this-modal" v-model="openShareModal">
        <template #modal-header>
          <h4> {{ $t("share-this.share-modal-title") }} </h4>
        </template>
        <template #default>
          <ShareThis :shareContent="shareContent"/>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button variant="danger" @click="cancel()">
            {{$t('booking.close')}}
          </b-button>
        </template>
      </b-modal>
    </main>
  </div>
</template>

<script>
import TemplateDealTab from './TemplateDealTab.vue';

export default {
  name: 'DealTab',
  components: {
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  extends: TemplateDealTab,
};
</script>

<style scoped>
  .price-text {
    cursor: pointer;
  }
</style>
