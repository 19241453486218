<template>
  <div class="deal-tab">
    <header-desktop />
    <main class="body_warrper">
      <!-- banner area start -->
      <div v-if="hotelImage !== ''" class="banner-area">
        <img :src="hotelImage" alt="#" title="" />
      </div>
      <div class="container tag-option d-flex">
        <div class="d-flex align-items-center">
          <button class="btn">{{type}}</button>
          <button class="btn">{{baggage}}</button>
          <button v-if="isTransfer" class="btn">כולל העברות</button>
          <button v-if="hasTitleRemark" class="btn">{{ titleRemark }}</button>
        </div>
      </div>
      <div class="share-button" @click="() => showModal(activeDeal)" v-if="testMode">
        <img :src="`${speedSizeDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
      </div>
      <div class="container descritpion d-flex">
        <div class="bannertextarea flex-grow-1">
          <h2>{{ hotelDest }}</h2>
          <p>{{ hotelSubTitle }}</p>
        </div>
      </div>
      <div class="tabarea">
        <div class="tab-heading">
            <div class="container d-flex">
              <button :class="['btn m-auto', {'active': isActive(index)}]" v-for="(tab, index) in tabs" :key="index" @click="activeTab(index)"><span>{{tab.title}}</span></button>
            </div>
        </div>
        <div class="tab-body">
          <div class="container tab" ref="containerIframe">
            <iframe :src="linkTab" ref="iframeDealTab"/>
          </div>
        </div>
      </div>
      <!-- price area start -->
      <div class="totalarea">
        <!-- <div class="heading-box"></div> -->
        <div class="textboxtotal">
          <div class="container d-flex">
            <div class="d-flex justify-content-center m-auto">
                <!-- <div class="textbox">
                    <h4>סה“כ תשלום סופי<strong>738$</strong></h4>
                </div> -->
                <div class="textbox">
                    <h4>סה“כ לנוסע<strong>{{ price }}</strong></h4>
                </div>
            </div>
            <button class="btndefoult d-flex m-auto" @click="gotoProduct">הזמן עכשיו</button>
          </div>
        </div>
      </div>
      <!-- price area stop -->
      <b-modal id="share-this-modal" v-model="openShareModal">
        <template #modal-header>
          <h4> {{ $t("share-this.share-modal-title") }} </h4>
        </template>
        <template #default>
          <ShareThis :shareContent="shareContent"/>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button variant="danger" @click="cancel()">
            {{$t('booking.close')}}
          </b-button>
        </template>
      </b-modal>
    </main>
    <footer-desktop :backLink="backLink" />
  </div>
</template>

<script>
import TemplateDealTab from './TemplateDealTab.vue';

export default {
  name: 'DealTab',
  components: {
    HeaderDesktop: () => import('@/components/bonauf/atoms/HeaderDesktop'),
    ShareThis: () => import('@/components/atoms/ShareThis'),
    FooterDesktop: () => import('@/components/bonauf/atoms/FooterDesktop'),
  },
  extends: TemplateDealTab,
  data() {
    return {
      backLink: '',
    }
  },
  created() {
    this.backLink = "/deal-filter/app-desktop?channel=App";
  },
  mounted() {
    window.addEventListener('message', this.resizeIframe);
  },
  methods: {
    resizeIframe () {
      // let iframe = this.$refs.iframeDealTab,
      //   container = this.$refs.containerIframe;
      // const { data } = event;
      // if (typeof data === 'object') {
      //   const {type, value} = data;
      //   switch(type) {
      //     case 'dealTab':
      //       if (value === 'loaded') {
      //         if(!container) return;
      //         container.style.height = (iframe.contentWindow.document.body.scrollHeight-68) + "px";
      //         window.removeEventListener('message', this.resizeIframe);
      //       }
      //       break;
      //     default:
      //   }
      // }
    }
  }
};
</script>

<style lang="less" scoped>
  @import "../../../../public/assets/less/bonaufDesktop.less";

  .deal-tab {
    .body_warrper {
      margin-top: -40px;
      position: relative;
      .banner-area {
        height: 220px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        }
      }
      .banner-area:after {
        width: 270px;
        height: 143px;
        left: 0;
        bottom: -56px;
        background-image: url(/assets/img/app/plane.png);
      }
      .tag-option {
        position: absolute;
        top: 60px;
        right: 50px;

        .btn {
          background: rgb(251,109,47);
          background: -moz-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
          background: linear-gradient(90deg, rgba(251,109,47,1) 0%, rgba(249,255,0,1) 100%);
          box-sizing: border-box;
          border-radius: 30px;
          padding: 8px 13px;
          color: #000;
          font-family: 'FbCoherentiSansBold';
          margin-left: 5px;
          font-size: 18px;
          line-height: 18px;
          border: none;
        }
      }
      .share-button {
        top: 60px;
        left: 30px;
      }
      .descritpion {
        margin: auto;
        margin-top: -90px;

        .align-items-center {
          z-index: 2;
        }

        .bannertextarea {
          z-index: 2;
          margin: auto;
          display: inline-flex;
          flex-direction: column;
          max-width: 520px;
          width: 100%;

          h2 {
            color: #fff;
            font-family: 'FbCoherentiSansBold';
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 0;
          }

          p {
            color: #fff;
            // font-family: 'ploniregularaaa';
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 15px;
          }
        }

      }
      .btn:focus {
        box-shadow: unset;
      }
    }

    .tabarea {
      .tab-heading {
        .container {
          max-width: 850px;
          padding: 0px 5px;

          .btn {
            span {
              font-size: 28px;
            }
            span:after {
              left: -25%;
              height: 5px;
              width: 150%;
            }
          }
        }
      }

      .tab-body {
        .tab {
          width: 50%;
          padding-bottom: 0px;

          iframe {
            min-height: 300px;
            height: 100%;
          }
        }

        @media (max-width: 1200px) {
          .tab {
            width: 65%;
          }
        }
        @media (max-width: 768px) {
          .tab {
            width: 90%;
          }
        }
      }
    }

    .totalarea {
      .heading-box {
        background: rgb(3,148,208);
        background: linear-gradient(90deg, rgba(3,148,208,1) 0%, rgba(132,219,255,1) 100%);
        box-sizing: border-box;
        padding: 30px 0;
      }

      .textboxtotal {
        position: fixed;
        bottom: 0px;
        width: 100%;
        background: #fff;
        z-index: 9;
        .textbox {
          margin: 0 24px;
          h4 {
            font-family: 'ploniregularaaa';
            font-size: 28px;
            color: #000;
            text-align: center;

            strong {
              display: block;
              font-family: 'FbCoherentiSansBold';
              font-size: 30px;
            }
          }
        }
      }
      .btndefoult {
        font-family: 'FbCoherentiSansBold';
        background: #fb732d;
        background: -webkit-linear-gradient(to right, #fb732d, #f9f304);
        background: linear-gradient(to right, #fb732d, #f9f304);
        border: none;
        font-size: 24px;
        padding: 10px 40px;
        border-radius: 16px;
        height: 60px;
        margin: auto;
      }
      .btndefoult:hover {
        background: #fb732d;
        background: -webkit-linear-gradient(to right, #f9f304, #fb732d);
        background: linear-gradient(to right, #f9f304, #fb732d);
      }
    }
  }
</style>
