<template>
  <div class="tabs-container clearfix" id="hotel_content">
    <div class="tabs-pad">
      <h3 class="tabs-container-title">{{hotel_name}}</h3>
      <p>
        <span id="star">
        </span>
      </p>
      <div class="starsWrap">
        <b-form-rating
          class="ui rating"
          variant="primary"
          inline
          :value="3"
          readonly
          locale="he"
          size='plaintext'
          v-if="hotel_grade>-1"
        />
        <p class="sr-only">{{hotel_grade}} כוכבים</p>
      </div>
      <p></p>
      <div>
        <p class='hotel-description'>{{ hotel_description }}</p>
        <b-img-lazy :src="hotel_img_url"
          width="100"
          height="100"
          alt="hotel-image"
          v-bind="mainProps"
          :onerror="`this.src='${speedSizeDomain}/assets/img/lesiurpackages_hotelsimg6.jpg'`"
        />
      </div>
      <p><strong>כתובת: </strong><br />{{ hotel_address }}</p>
      <p v-if="hotel_webSite_url !== ''"><strong>אתר אינטרנט: </strong><br><a rel="nofollow" :href="hotel_webSite_url">{{ hotel_webSite_url }}</a></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating, BImgLazy } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: { BFormRating, BImgLazy },
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      lang: 'he',
      hotel_name: 'hotel name',
      hotel_grade: 5,
      hotel_description: 'hotel description',
      hotel_address: 'hotel address',
      hotel_webSite_url: 'hotel web site url',
      hotel_img_url: `${this.speedSizeDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`,
    };
  },
  watch: {
    appDealPageData() {
      this.fillData();
    },
  },
  computed: {
    ...mapGetters({
      appDealPageData: 'GET_APP_DEAL_DATA',
    }),
  },
  async created() {
    if (!this.$route.query.dealId) return;
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('FETCH_APP_DEAL_PAGE_DATA', { dealId: this.$route.query.dealId, lang: this.lang });
    },
    fillData() {
      if (this.appDealPageData) {
        this.hotel_name = this.appDealPageData.hotelName[this.lang];
        this.hotel_grade = this.appDealPageData.stars;
        this.hotel_description = this.appDealPageData?.appDealExt?.hotelInfo?.description || '';
        this.hotel_address = this.appDealPageData?.appDealExt?.hotelInfo?.address || '';
        this.hotel_webSite_url = this.appDealPageData?.appDealExt?.hotelInfo?.webSiteUrl || '';
        this.hotel_img_url = this.appDealPageData.perspectiveUrl[0] || this.appDealPageData.imageUrl || this.appDealPageData.destImageUrls[0] || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      }
    },
  },
};
</script>

<style scoped>
  .hotel-description {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 5px;
  }
</style>
