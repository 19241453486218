<template>
  <div>
    <div v-if="device == 'desktop'">
      <div class="holiday-hotel-details">
        <h2>
          {{ transCategoryName }} {{ $t('product-page.for') }}
          {{ transDestinatinName }}-{{ transHotelName }}
        </h2>
      </div>
      <div class="clearfix"></div>
      <Breadcrumb
        type="flat"
        :stage="bookingStage"
        v-if="device == 'desktop'"
      />
    </div>
    <div v-else>
      <mobile-header :product="product" />
      <mobile-head-banner :stage="bookingStage" />
    </div>
    <div class="container p-0">
      <div
        class="new_booking_main_area dVacation-pack booking_mobile_part mt-3"
      >
        <div class="booking_details_three">
          <div class="price_details">
            <div class="price_details_main_body">
              <div class="price_details_body">
                <div v-if="device === 'desktop'">
                  <h4>{{ transHotelName }}</h4>
                  <div class="d-flex mb-3">
                    <b-form-rating
                      class="ui rating"
                      variant="warning"
                      inline
                      :value="rating"
                      readonly
                      locale="he"
                    ></b-form-rating>
                  </div>
                  <RoomInformation
                    v-for="(room, idx) in rooms"
                    :key="idx"
                    :room="room"
                    :idx="idx"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="passenger_detail">
            <div>
              <div class="passenger_details_main_area booking_mobile_part mt-3">
                <div v-if="device == 'desktop'">
                  <div
                    class="passenger_box_one"
                    style="`background-image: url(${speedSizeDomain}/assets/img/passenger-box-banner-two.jpg);`"
                  >
                    <h2>
                      {{ $t('booking.passenger-details') }}
                      <span class="icon"><i class="fas fa-user"></i></span>
                    </h2>
                  </div>
                </div>
                <div class="container guest_details_body" v-else>
                  <h3>
                    {{ $t('booking.guest-desc') }}
                    <span class="icon"><i class="fas fa-user"></i></span>
                  </h3>
                  <!-- <p>{{ $t('booking.guest-desc') }}</p> -->
                </div>
                <UserListRoom
                  v-for="(room, idx) in rooms"
                  :key="idx"
                  :category="categoryCode"
                  :room="room"
                  :index="idx"
                  @updatedUser="updatingUser"
                />
              </div>
            </div>
          </div>

          <payer-information @updatePayer="updatingPayer" />

          <div
            class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5"
          >
            <div class="booking_details_three">
              <div class="price_details">
                <div class="price_details_heading" v-if="device == 'desktop'">
                  <h3>{{ $t('booking.reservation-terms') }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body">
                    <div class="terms_box">
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedTerms"
                          />{{ $t('booking.i-agree-to') }}
                          <strong>{{ $t('booking.agree-desc') }}</strong>
                          {{ $t('booking.read-desc') }}
                          <strong>{{ $t('booking.read-desc-corona') }}</strong>
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            readonly
                            checked
                          />
                          {{ $t('booking.email-receive-agree') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            readonly
                            checked
                          />
                          {{ $t('booking.telephone-provide-agree') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="total_booking_price" v-if="device == 'desktop'">
                <div class="d-flex justify-content-between">
                  <button
                    class="btn-booking"
                    @click="BookingNow"
                    :disabled="disableBook"
                  >
                    {{ $t('search-result.book-now') }}
                  </button>
                  <div class="price_box">
                    <h3>
                      <span>:{{ $t('booking.total-price') }}</span
                      >{{ totalPriceLabel }}
                    </h3>
                  </div>
                  <div class="price_box">
                    <h3>
                      <span>:{{ $t('booking.total-to-price') }}</span
                      >{{ totalPriceLabel }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="total_payment" v-else>
                <div class="container">
                  <div class="d-flex justify-content-between">
                    <h4>
                      <span>{{ $t('booking.total-payment') }}</span
                      >{{ totalPrice }} $
                    </h4>
                    <button
                      class="btn"
                      @click="BookingNow"
                      :disabled="disableBook"
                    >
                      {{ $t('search-result.book-now') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="countDownModal"
      centered
      size="lg"
      :dir="lang == 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :ok-only="true"
    >
      <template #modal-header="">
        <div
          class="d-flex flex-grow-1 align-items-baseline"
          :class="[lang == 'he' ? 'text-right' : 'text-left']"
        >
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
          <h5>{{ maxSec }} {{ $t('booking.sec') }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{
          $t('booking.go-product-page')
        }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button
          size="lg"
          variant="success"
          @click="
            gotoProductPage();
            ok();
          "
        >
          {{ $t('booking.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-toast
      id="countDown"
      toaster="b-toaster-top-center"
      variant="warning"
      :autoHideDelay="maxSec * 1000"
      solid
      v-model="showCountDown"
      :classes="[lang == 'he' ? 'rtl' : 'ltr']"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ maxSec }} {{ $t('booking.sec') }}</h5>
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      {{ $t('booking.go-product-page') }}
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating, BToast, BModal, BButton } from 'bootstrap-vue';
import dayjs from 'dayjs';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  data() {
    return {
      // loadDataPaxPayer : true,
      rating: 0,
      query: null,
      bookingStage: 2,

      product: null,
      category: null,
      hotelName: '',
      rooms: [],
      // flightItems: [],
      categoryCode: '',
      userList: [],

      payerInfo: {
        firstNamePayer: '',
        lastNamePayer: '',
        phonePayer: '',
        emailPayer: '',
        idCardNumberPayer: '',
        // CreditCardExpirYear: '',
        // CreditCardExpirMonth: '',
        NoOfPayment: '1',
      },

      totalPrice: 0,
      totalPriceLabel: '0$',

      availableUsers: false,
      availablePayer: false,

      disableBook: false,
      timeout: -1,

      showCountDown: false,
      maxSec: 10,

      transCategoryName: '',
      transDestinatinName: '',
      transHotelName: '',

      timeID: -1,
    };
  },
  beforeMount() {
    this.query = this.$route.query;
  },
  components: {
    BFormRating,
    BToast,
    BButton,
    BModal,
    MobileHeader: () => import('@/components/booking/atoms/MobileHeader'),
    MobileHeadBanner: () => import('@/components/booking/atoms/MobileHeadBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    RoomInformation: () => import('@/components/booking/atoms/RoomInformation'),
    UserListRoom: () => import('@/components/booking/atoms/UserListRoom'),
    PayerInformation: () => import('@/components/booking/atoms/PayerInformation'),
  },
  computed: {
    ...mapGetters({
      categoryState: 'GET_CURRENT_CATEGORY',
      productState: 'GET_PRODUCT',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      devMode: 'GET_MODE',
    }),
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  methods: {
    async fetchData() {
      const localProduction = JSON.parse(
        window.sessionStorage.getItem('production'),
      );
      this.category = this.categoryState || localProduction.category;
      this.product = this.productState || localProduction;

      this.rating = this.product.hotels[0].grade;

      this.rooms = JSON.parse(window.sessionStorage.getItem('roomList')).roomList;
      this.rooms.forEach((room) => {
        room.NoNight = this.product.hotels[0].duration;
        const checkIn = dayjs(this.product.hotels[0].checkIn),
          checkOut = dayjs(this.product.hotels[0].checkOut);
        room.checkIn = checkIn.format('DD-MM-YYYY');
        room.checkOut = checkOut.format('DD-MM-YYYY');
        room.translations = null;
      });

      this.hotelName = this.product.hotels[0].hotelName;
      if (this.product.flights && this.product.flights.length > 0) {
        if (this.discounted) {
          this.flightItems = this.product.flights.filter(
            (item) => `${item.FlightDetail[0].FL_ID}${item.FlightDetail[1].FL_ID}`
              === this.$route.query.flights,
          );
        } else {
          this.flightItems = this.product.flights;
        }
      }
    },
    updateLabelWithLang() {
      this.transCategoryName = this.product.category.name[this.lang] || this.category.code;
      this.transDestinatinName = this.product.hotels[0].cityName;
      this.transHotelName = this.product.hotels[0].hotelName;
      this.totalPriceLabel = this.lang === 'he' ? `${this.product.price}$ ( ${this.product.priceNIS}₪ )` : `${this.product.price}$`;
    },
    updatingUser(updatedUser) {
      const user = this.userList.find(
        (pUser) => pUser.id === updatedUser.id && pUser.groupID === updatedUser.groupID,
      );
      if (user !== undefined) {
        Object.assign(user, updatedUser);
      } else {
        this.userList.push(updatedUser);
      }
    },
    updatingPayer(updatedPayer) {
      this.payerInfo = updatedPayer;
      this.availablePayer = updatedPayer.available;
    },
    formattingBookData() {
      let data = {
        data: null,
        sendingStage: 'update',
      };
      if (this.categoryCode === 'Hotel_Only') {
        const currentHotel = this.product.activeHotel[0],
          hotel = this.product.hotel[0];
        const bookingData = {
          agentScreenResolultion: this.device,
          grandTotalAfterDiscount: this.totalPrice,
          currencyCode: hotel.currency,
          couponCode: null,
          hotel: {
            hotelCode: currentHotel.code,
            hotelName: currentHotel.name,
            checkinDate: hotel.stay.checkIn,
            checkoutDate: hotel.stay.checkOut,
            remarkInVoucher: '',
            cityCode: currentHotel.destinationCode,
            cityName: currentHotel.destinationName,
            rooms: [],
          },
          paxList: [],
          payer: {
            name: '',
            email: '',
            mobile: '',
            // creditCardExpirMonth: '',
            // creditCardExpirYear: '',
            identificationNumber: '',
            // noOfPayment: 1,
          },
        };

        let paxId = 0;
        const paxes = [];
        this.rooms.forEach((room) => {
          const paxIds = [];
          for (let i = 0; i < room.adult; i += 1) {
            paxId += 1;
            paxes.push({
              id: paxId,
              paxType: 'AD',
              lastName: '',
              firstName: '',
              birthDate: '',
              email: '',
              phone: '',
            });
            paxIds.push(paxes.length - 1);
          }
          for (let i = 0; i < room.child; i += 1) {
            paxId += 1;
            paxes.push({
              id: paxId,
              paxType: 'CH',
              lastName: '',
              firstName: '',
              birthDate: '',
              email: '',
              phone: '',
            });
            paxIds.push(paxes.length - 1);
          }
          for (let i = 0; i < room.infant; i += 1) {
            paxId += 1;
            paxes.push({
              id: paxId,
              paxType: 'CH',
              lastName: '',
              firstName: '',
              birthDate: '',
              email: '',
              phone: '',
            });
            paxIds.push(paxes.length - 1);
          }
          bookingData.hotel.rooms.push({
            rateKey: room.rate.rateKey,
            totalAfterDiscount: room.totalPrice,
            discountPercent: null,
            totalOriginal: room.oldTotal,
            nett: room.price,
            currencyCode: room.currency,
            roomCode: room.roomType.value,
            roomName: room.roomType.text,
            basisCode: room.roomBasisCode,
            basisName: room.roomBaiss,
            priceListCode: null,
            adults: room.adult,
            children: room.child,
            infant: room.infant,
            paxIds,
          });
        });
        bookingData.paxList = paxes;

        data = {
          data: bookingData,
          sendingStage: 'init',
        };
      } else {
        const production = JSON.parse(
          window.sessionStorage.getItem('production'),
        );
        let userID = 0;
        const userIdList = [];
        this.availableUsers = true;
        this.userList.forEach((user) => {
          userID += 1;
          user.id = String(userID);
          userIdList.push(String(userID));
          if (!user.validate) this.availableUsers = false;
        });
        const outwardFlight = production.flights[0].FlightDetail[0],
          inwardFlight = production.flights[0].FlightDetail[1];

        const paxList = [];
        this.userList.forEach((user) => {
          const clone = { ...user };
          const birthday = dayjs(clone.birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
          clone.birthDate = birthday;
          delete clone.groupID;
          paxList.push(clone);
        });

        const selectedRooms = [],
          gHotel = production.hotels[0];
        let roomIndex = 0;
        this.rooms.forEach((room) => {
          const UIDRoom = [];
          this.userList.forEach((user) => {
            if (user.groupID === roomIndex) {
              UIDRoom.push(String(user.id));
            }
          });
          const roomData = {
            hotelId: Number(gHotel.La_ID),
            cityCode: gHotel.city,
            hotelSupplierCode: gHotel.Supplier,
            basisCode: gHotel.basic_fare,
            rooms: {
              roomClassCode: room.roomClass.value,
              roomTypeCode: room.roomType.value,
              accomodation: 1,
              bBCots: Number(room.infant),
              paxIds: UIDRoom,
            },
          };

          roomIndex += 1;
          selectedRooms.push(roomData);
        });

        const bookInfo = {
          packageId: production.packId,
          hotel_shift_1: production.hotel_shift_1,
          duration: production.duration_1,
          discountPercent: production.discountPercent || null,
          price: this.totalPrice,
          currency: production.cc,
          packSelectionId: production.packSelectionId,
          paxList,
          outwardFlight: {
            flightId: outwardFlight.FL_ID,
            departureCityCode: outwardFlight.FL_From_Air_Port,
            departureDate: outwardFlight.FL_Date,
            departureTime: outwardFlight.FL_Dep_Hour,
            arrivalCity: outwardFlight.FL_To_Air_Port,
            class: this.flightItems[0].Class1,
            paxIds: userIdList,
          },
          inwardFlight: {
            flightId: inwardFlight.FL_ID,
            departureCityCode: inwardFlight.FL_From_Air_Port,
            departureDate: inwardFlight.FL_Date,
            departureTime: inwardFlight.FL_Dep_Hour,
            arrivalCity: inwardFlight.FL_To_Air_Port,
            class: this.flightItems[0].Class2,
            paxIds: userIdList,
          },
          hotel: selectedRooms,
          payer: {
            firstName: this.payerInfo.firstNamePayer,
            lastName: this.payerInfo.lastNamePayer,
            email: this.payerInfo.emailPayer,
            mobile: this.payerInfo.phonePayer,
            IdenticationNumber: this.payerInfo.idCardNumberPayer,
            // CreditCardExpirMonth: this.payerInfo.CreditCardExpirMonth,
            // CreditCardExpirYear: this.payerInfo.CreditCardExpirYear,
            NoOfPayment: Number(this.payerInfo.NoOfPayment),
          },
        };
        data = {
          data: bookInfo,
          sendingStage: 'update',
        };
      }
      return data;
    },
    async sendBookingInformation() {
      const data = this.formattingBookData();
      const response = await this.$store.dispatch(
        'SEND_BOOKING_DATA_HOTEL_ONLY',
        data,
      );
      return response;
    },
    async BookingNow() {
      if (!this.checkedTerms) {
        const message = this.$t('booking.toast-confirm-reservation');
        this.$bvToast.toast(
          message,
          {
            title: this.$t('booking.toast-information'),
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          },
        );
      } else {
        this.disableBook = true;
        let response = { success: true };
        const retry = window.sessionStorage.getItem('retryState');
        let saveBookData = true;
        if (retry !== 'null') {
          const oldState = JSON.parse(retry);
          saveBookData = !oldState.readOnly;
          this.availableUsers = oldState.readOnly;
        }
        this.formattingBookData();
        // console.log(this.availablePayer, this.availableUsers);
        const marketerURL = window.sessionStorage.getItem('marketerURL');
        if (this.availablePayer && this.availableUsers) {
          if (saveBookData) {
            response = await this.sendBookingInformation();
          } else {
            response.id = window.sessionStorage.getItem('BookingDataID');
          }
          if (response.success) {
            if (response.data.bookingTransactionId !== '') {
              const { host } = window.location;
              const http = (host === 'localhost:8080' || host === 'flyingcarpet.localhost.com' || host === 'FlyingCarpet.localhost.com') ? 'http://' : 'https://';
              const info = {
                email: this.payerInfo.emailPayer,
                mobile: this.payerInfo.phonePayer,
                firstName: this.payerInfo.firstNamePayer,
                lastName: this.payerInfo.lastNamePayer,
                // CreditCardExpirMonth: this.payerInfo.CreditCardExpirMonth,
                // CreditCardExpirYear: this.payerInfo.CreditCardExpirYear,
                IdenticationNumber: this.payerInfo.idCardNumberPayer,
                NoOfPayment: Number(this.payerInfo.NoOfPayment),
                successUrl: `${http}${host}/redirect-verified`,
                // successUrl: 'http://localhost:8080/redirect-verified',
                failureUrl: `${http}${host}/redirect-verified1`,
                cssUrl: `${http}${host}//assets/css/styleVerify.css`,
              };
              const res = await this.$store.dispatch('GET_PAYMENT_URL', {
                payerInfo: info,
                bookID: response.id,
              });
              if (res.success) {
                if (!res.error) {
                  /* window.removeEventListener("beforeunload", this.ClosingWindow);
                  let verifyWindow = window.open(res.url.paymentUrl, "_self", "");
                  verifyWindow.focus(); */

                  this.$store.dispatch('UPDATE_VERIFYING_URL', res.url.paymentUrl);
                  this.$router.push({ path: '/booking/verifying-card' });
                } else if (res.error.retryAllowed) {
                  const message = res.error.retryPaymentMethodOnly
                    ? this.$t('booking.toast-fill-payer-information')
                    : this.$t('booking.toast-pax-payer-information');

                  this.$bvToast.toast(message, {
                    title: res.error.code,
                    autoHideDelay: 10000,
                    appendToast: true,
                    variant: 'danger',
                    toaster: 'b-toaster-top-right',
                    noCloseButton: true,
                    bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
                  });
                  this.disableBook = false;
                } else {
                  const message = this.devMode
                    ? this.$t('booking.toast-server-internal-error')
                    : res.error.message;
                  this.$bvToast.toast(message, {
                    title: res.error.code,
                    autoHideDelay: 5000,
                    appendToast: true,
                    variant: 'danger',
                    toaster: 'b-toaster-top-right',
                    noCloseButton: true,
                    bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
                  });
                  const oneself = this;
                  setTimeout(() => {
                    oneself.$router.push({ path: '/' });
                  }, 5000);
                }
              } else if (res.error.response.status === 400) {
                const message = this.$t('booking.toast-fill-payer-information');
                this.$bvToast.toast(message, {
                  title: res.error.response.title,
                  autoHideDelay: 10000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-right',
                  noCloseButton: true,
                  bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
                });
                this.disableBook = false;
              } else {
                const message = this.devMode
                  ? this.$t('booking.toast-server-internal-error')
                  : res.error.message;
                this.$bvToast.toast(message, {
                  title: res.error.code,
                  autoHideDelay: 5000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-right',
                  noCloseButton: true,
                  bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
                });
                const oneself = this;
                setTimeout(() => {
                  oneself.$router.push({ path: '/' });
                }, 5000);
              }
            } else if (response.data.bookingTransactionId === '') {
              const message = this.$t('booking.toast-back-product');
              this.$bvTosast.toast(message, {
                title: this.$t('booking.toast-information'),
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-top-right',
                noCloseButton: true,
                bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
              });
              const oneself = this;
              setTimeout(() => {
                oneself.$router.push({ path: marketerURL });
              }, 5000);
            }
          } else {
            const message = this.$t('booking.toast-back-product');
            this.$bvToast.toast(
              message,
              {
                title: this.$t('booking.toast-information'),
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-top-right',
                noCloseButton: true,
                bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
              },
            );
            this.disableBook = false;
            const oneself = this;
            setTimeout(() => {
              oneself.$router.go(-1);
            }, 5000);
          }
        } else {
          const message = this.$t('booking.toast-user-payer-information');
          this.$bvToast.toast(message, {
            title: this.$t('booking.toast-information'),
            autoHideDelay: 10000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          });
          this.disableBook = false;
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    async ClosingWindow(ev) {
      const data = this.formattingBookData();
      const bookingDataID = this.bookingDataID || window.sessionStorage.getItem('BookingDataID');
      this.$store.dispatch('SEND_CLOSING_NOTICE', {
        bookDataID: bookingDataID,
        data: JSON.stringify(data.data),
        withData: true,
      });
    },
    gotoProductPage() {
      clearInterval(this.timeID);
      this.$bvModal.hide('countDownModal');
      this.$store.dispatch('REDIRECT_TO_PRODUCT');
      this.$router.go(-1);
    },
  },
  created() {
    // eslint-disable-next-line no-unused-vars

    // this.loadDataPaxPayer = true;
    this.disableBook = false;
    this.$emit('setBookingStage', 2);
    this.fetchData();
    let sum = 0;
    this.rooms.forEach((room) => {
      sum += room.totalPrice;
    });
    this.totalPrice = sum;
    this.totalPriceLabel = this.lang === 'he' ? `${this.product.price}$ ( ${this.product.priceNIS}₪ )` : `${this.product.price}$`;
    this.categoryCode = this.category.code;

    const retry = JSON.parse(window.sessionStorage.getItem('retryState')),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      { payer } = dataBook.data,
      { sendingStage } = dataBook;

    if (retry != null && sendingStage !== 'init') {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.payerInfo.firstNamePayer = name[0];
        this.payerInfo.lastNamePayer = name[1];
      }
      this.payerInfo.phonePayer = payer.mobile;
      this.payerInfo.emailPayer = payer.email;
      this.payerInfo.idCardNumberPayer = payer.IdenticationNumber;
      // this.payerInfo.CreditCardExpirYear = payer.CreditCardExpirYear;
      // this.payerInfo.CreditCardExpirMonth = payer.CreditCardExpirMonth;
      this.payerInfo.NoOfPayment = Number(payer.NoOfPayment);
    }
    this.updateLabelWithLang();
    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
    const retry = JSON.parse(window.sessionStorage.getItem('retryState')),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      // eslint-disable-next-line no-unused-vars
      { payer } = dataBook.data,
      { sendingStage } = dataBook;
    if (retry == null && sendingStage !== 'init') {
      // this.$bvToast.show('countDown');
      this.$bvModal.show('countDownModal');
      const oneself = this;
      this.timeID = setInterval(() => {
        oneself.maxSec -= 1;
        if (oneself.maxSec === 0) {
          oneself.gotoProductPage();
        }
      }, 1000);
    }

    dataBook.sendingStage = 'update';
    // window.sessionStorage.setItem('BookingDetailInfo', JSON.stringify(dataBook));
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
};
</script>

<style>
#b-toaster-top-center {
  top: calc(50vh - 70px);
}
#countDownModal.modal .modal-dialog {
  display: flex;
  font-family: 'ploni', 'Poppins', sans-serif;
  font-weight: 500;
}
#countDownModal.modal:before {
  display: none;
}
#countDownModal.modal .modal-body {
  display: flex;
}
/* #countDown .ltr {
  direction: ltr;
  text-align: left;
}
#countDown .rtl {
  direction: rtl;
  text-align: right;
} */
</style>

<style scoped>
#countDown h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#countDown h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.price_details_heading {
  padding: 20px 30px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.price_details_main_body {
  padding: 30px 30px;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.price_details_heading h3 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0;
  padding: 0 0;
  text-transform: uppercase;
}

.price_details_body h4 {
  color: #000;
  text-transform: capitalize;
  font-size: 25px;
  margin-bottom: 2px;
}

.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}

.passenger_details_main_area .passenger_box_one {
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
}

.passenger_details_main_area .passenger_box_one h2 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  position: relative;
  font-size: 30px;
  text-transform: capitalize;
  padding-right: 45px;
  direction: ltr;
}

.passenger_details_main_area {
  color: #fff;
}
.passenger_details_main_area .passenger_box_one h2 .icon {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
}

.passenger_box_two {
  border-radius: 5px;
}

.terms_box .form-check {
  margin-bottom: 10px;
}

.terms_box .form-check label {
  font-size: 15px;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: -1.25rem;
}

.form-check label strong {
  font-weight: 400;
  color: #0061ab;
}

.total_booking_price {
  background: #0161ab;
  padding: 30px 30px;
  box-sizing: border-box;
}

.total_booking_price .btn-booking {
  background: #ffffff;
  color: #0060aa;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 500;
}

.total_booking_price .btn-booking:hover {
  background: #528ab5;
}

.total_booking_price .price_box h3 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  font-size: 30px;
}

.total_booking_price .price_box h3 span {
  display: block;
  font-size: 15px;
  text-align: left;
  margin-bottom: 2px;
}

.guest_details_body h3 {
  padding: 0 0 7px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  border-bottom: 2px solid #bfbfbf;
  margin-bottom: 10px;
  position: relative;
  padding-right: 25px;
}
.guest_details_body h3 .icon {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
}
.guest_details_body p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #212529;
}
#countDown {
  direction: ltr;
}

@media (min-width: 992px) {
  .price_details_main_body {
    padding: 30px 1px;
  }
}

@media (max-width: 479px) {
  .price_details_main_body {
    padding: 0px;
  }

  .terms_box .form-check label {
    font-size: 12px;
    color: #212529;
  }
  .form-check {
    margin-bottom: 8px;
  }

  .form-check label strong {
    color: #212529;
    font-weight: bolder;
  }

  .total_payment {
    padding: 20px 0px 0px;
    overflow: hidden;
    box-shadow: 0px -2px 2px rgba(221, 221, 221, 0.5);
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 91;
  }
  .total_payment .btn {
    background: #0061ab;
    color: #fff;
    text-transform: lowercase;
    padding: 0 14px;
    line-height: 0;
    height: 34px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }
  .total_payment h4 {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-right: 10px;
  }
  .total_payment h4 span {
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
}
</style>
