<template>
  <div>
    <b-button class="m-3" variant="primary" v-if="selectedDeals" @click="exportWord">{{ $t('price-plan.export-word') }}</b-button>
    <div class="text-center"><b-spinner style="width: 3rem; height: 3rem;" label="Spinning" v-if="isLoading"></b-spinner></div>
    <h1 v-if="!selectedDeals && !isLoading" class="m-5 p-5 text-center" dir="ltr">{{ $t('price-plan.changes.no-deals')}}</h1>
    <div ref="exportDoc" v-if="selectedDeals" >
      <h1 style="text-align:center;">{{ $t(`price-plan.changes.${pageType}-title`)}}</h1>

      <p style="line-height: 1rem;text-align:center;margin:0;"><a :href="`${baseDomain}${$route.fullPath}`">Change ID: {{changeId}}</a></p>
      <p v-if="currentSnapshotTime" style="line-height: 1rem;text-align:center;margin:0;">
        {{ $t("price-plan.changes.current-time", {time: formatTime(currentSnapshotTime) || ''}) }}
      </p>
      <p v-if="previousSnapshotTime" style="line-height: 1rem;margin-top:0;margin-bottom:10px;text-align:center;">
        {{ $t("price-plan.changes.previous-time", {type: $t(`price-plan.changes.${pageType}`) || $t('price-plan.changes.noChanged'), time: formatTime(previousSnapshotTime) || ''}) }}
      </p>

      <price-plan-item v-for="(item, inx) in selectedDeals" :key="inx" :item="item" type="changes" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BSpinner } from 'bootstrap-vue';
import exportDoc from '@/utils/exportWordPDF';

const { VUE_APP_MAIN_DOMAIN } = process.env;

export default {
  name: 'changedDealSubPage',
  mixins: [exportDoc],
  components: {
    PricePlanItem: () => import('@/components/pricePlan/PricePlanItem'),
    BButton,
    BSpinner,
  },
  data() {
    return {
      baseDomain: VUE_APP_MAIN_DOMAIN,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      selectedChangedPrices: 'GET_CHANGED_DEALS_FOR_MARKETER',
      device: 'GET_DEVICE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
    }),
    selectedDeals() {
      const { selectedChangedPrices, pageType } = this;
      const deals = selectedChangedPrices(pageType)?.deals || [];
      if (!deals || deals?.length === 0) return null;
      return deals;
    },
    currentSnapshotTime() {
      const { selectedChangedPrices, pageType } = this;
      const time = selectedChangedPrices(pageType)?.currentSnapshotTime || null;
      return time;
    },
    previousSnapshotTime() {
      const { selectedChangedPrices, pageType } = this;
      const time = selectedChangedPrices(pageType)?.previousSnapshotTime || null;
      return time;
    },
    pageType() {
      return this.$route.meta.type;
    },
    changeId() {
      return this.$route.query.changeId;
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'price-plan');
  },
  mounted() {
    this.$store.dispatch('FETCH_CHANGED_DEALS_FOR_MARKETER', { type: this.pageType, changeId: this.changeId });
  },
  methods: {
    formatTime(timeString) {
      if (!timeString) return '';
      const date = timeString.substr(0, 16).split('T')[0].split('-').reverse().join('/');
      const time = timeString.substr(0, 16).split('T')[1];

      return `${date} ${time}`;
    },
  },
};
</script>
<style>

</style>
