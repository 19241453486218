<template>
  <div class="container">
    <div v-if="loading">
      <content-loading type="product" />
    </div>
    <div v-else >
      <div v-if="variant != 'success'" >
        <b-card-group deck>
          <b-card
            :border-variant="variantClass"
            :header-bg-variant="variantClass"
            :body-text-variant="variantClass"
            align="right"
          >
            <b-card-text :dir="lang == 'he' ? 'rtl' : 'ltr'">{{ message }}</b-card-text>

            <!-- <div class="row">
              <div class="col-12 col-md-6">
                <b-card-text><a :href="bookingInfoPageLink" target="_blank">{{$t('booking.error-link-message')}}</a></b-card-text>
              </div>
              <div class="col-12 col-md-6">
                <b-button variant="outline-primary" :disabled="orderNo==='N/A'"><a :href="`/check-booking`" target="_blank">Show booking information</a></b-button>
              </div>
            </div> -->
            <b-overlay :show="sendingMessage" rounded="sm" class="d-inline-block" spinner-variant="primary">
              <b-button variant="primary" v-if="isOdysseySite" :disabled="sendingMessage || successfullySentMessage" @click="sendMessage">{{$t("booking.send-message-button")}}</b-button>
            </b-overlay>
          </b-card>
        </b-card-group>
        <button class="btn backDebit" @click="goToDebit" v-if="allowRetry">
          {{ $t('booking.try-again-debit') }}
        </button>
      </div>
      <!-- <div v-else> -->
      <div>
        <div class="container" v-if="device == 'desktop'">
          <h1>
            <strong>{{ $t(`booking.status${this.payer.status}`) }}</strong>
          </h1>
        </div>
      </div>
      <div class="after_booking_area">
        <div
          class="order_no_box"
          :style="`background-image: url(${speedSizeDomain}/assets/img/after-booking-img1.jpg)`"
        >
          <div class="d-flex justify-content-start">
            <!-- <div class="order_no_div">
              <p>
                {{ $t('booking.for-order-tracking') }},
                {{ $t('booking.please') }}
                <a href="#">{{ $t('booking.click-here') }}</a
                ><br />
                {{ $t('booking.keep-order-name-desc') }}
              </p>
            </div> -->
            <div class="order_no_div">
              <h2>
                {{ $t('booking.your-order-no-is') + ' '
                }}<strong class="order_no">{{ orderNo }}</strong>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <payer-confirm :payer="payer" />

      <hotel-confirm :bookedHotel="bookedHotel" v-if="stateBooking == 'FH'" />

      <div class="please_mall_area" >
        <div class="row">
          <div class="col-lg-6" v-if="false">
            <div class="img_box">
              <img :src="`${speedSizeDomain}/assets/img/after-booking-img2.jpg`" width="100" height="100" alt="after-booking"/>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="plase_mall_contain_box">
              <h3>
                <span>{{ $t('booking.please-note') }}:</span>
                {{ $t('booking.attached-mail') }}
              </h3>
              <div class="please_mall_contain_body" v-if="showLink || this.devMode">
                <div class="d-flex justify-content-between">
                  <div class="please_mall" v-if="(isMaccabiAgency && pdfUrlReceipts && pdfUrlTerms.length) || this.devMode">
                    <div class="icon_box" v-for="(pdf, inx) in pdfUrlReceipts" :key="inx">
                      <b-link
                        :href="pdf"
                        @click.prevent="downloadTickets(pdf, 'Receipt')"
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon14.png`" class="col-10" width="100" height="100" alt="after-booking"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.receipt') }}</h5>
                  </div>
                  <div class="please_mall" v-else-if="pdfUrlReceipt || this.devMode">
                    <div class="icon_box">
                      <b-link
                        :href="pdfUrlReceipt"
                        @click.prevent="
                          downloadTickets(pdfUrlReceipt, 'Receipt')
                        "
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon14.png`" class="col-10" width="100" height="100" alt="after-booking"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.receipt') }}</h5>
                  </div>
                  <div class="please_mall" v-if="pdfUrlTerms || this.devMode">
                    <div class="icon_box">
                      <b-link
                        :href="pdfUrlTerms"
                        @click.prevent="
                          downloadTickets(pdfUrlTerms, 'pdfUrlTerms')
                        "
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon15.png`" width="100" height="100" alt="after-booking"/></b-link>
                    </div>
                    <h5>{{ $t('booking.general-terms') }}</h5>
                  </div>
                  <div class="please_mall" v-if="pdfUrlItinerary || this.devMode">
                    <div class="icon_box">
                      <b-link
                        :href="pdfUrlItinerary"
                        @click.prevent="
                          downloadTickets(pdfUrlItinerary, 'Itinerary')
                        "
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon16.png`" width="100" height="100" alt="after-booking"
                      /></b-link>
                    </div>
                    <h5>{{ $t('booking.itinerary') }}</h5>
                  </div>
                  <div class="please_mall" v-if="pdfUrlTicket || this.devMode">
                    <div class="icon_box">
                      <b-link
                        :href="pdfUrlTicket"
                        @click.prevent="downloadTickets(pdfUrlTicket, 'Ticket')"
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon17.png`" width="100" height="100" alt="after-booking"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.flight-tickets') }}</h5>
                  </div>
                  <div class="please_mall" v-if="pdfUrlHotelVoucher || this.devMode">
                    <div class="icon_box">
                      <b-link
                        :href="pdfUrlHotelVoucher"
                        @click.prevent="
                          downloadTickets(pdfUrlHotelVoucher, 'HotelVoucher')
                        "
                        ><img :src="`${speedSizeDomain}/assets/img/after-booking-icon18.png`" width="100" height="100" alt="after-booking"
                      /></b-link>
                    </div>
                    <h5>{{ $t('booking.hotel-voucher') }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <flight-confirm :flights="flights" />
      <div v-if="isMobileApp" class="d-flex mt-3">
        <div class="m-auto">
          <input :value="$t('product-page.rate-app-button')" type="button" name="submit"  id="btnSubmit" @click="handleRatingApp" />
          {{ $t("product-page.rate-app-label") }}
        </div>
      </div>
    </div>
    <!-- <b-toast
      id="waiting"
      :autoHideDelay="waitingTime"
      solid
      :class="[lang == 'he' ? 'rtl' : 'ltr']"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h3>{{ $t('booking.waiting') }}</h3>
        </div>
      </template>
      <div class="d-flex">
        <h5>{{ $t('booking.waiting-populating') }}</h5>
        <img :src="`${speedSizeDomain}/assets/img/waiting.gif`" alt="waiting"/>
      </div>
    </b-toast> -->

    <b-modal id="maccabiErrorModal" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true">
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <span >
            {{ maccabiErrorMessage }}
          </span>
        </div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok(); gotoRetryForMaccabi()">
          {{ $t('booking.than') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BCardGroup, BCard, BCardText, BButton, BOverlay, BModal } from 'bootstrap-vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import googleAnalytics from '@/utils/googleAnalytics';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  components: {
    BLink,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BOverlay,
    BModal,
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PayerConfirm: () => import('@/components/booking/atoms/PayerConfirm'),
    HotelConfirm: () => import('@/components/booking/atoms/HotelConfirm'),
    FlightConfirm: () => import('@/components/booking/atoms/FlightConfirm'),
  },
  mixins: [gMixin, googleAnalytics, imageUrlMixin],
  data() {
    return {
      loading: false,
      bookingStage: 3,
      variantClass: 'success',
      variant: 'success',
      paymentSucceeded: 0,
      header: '',
      message: '',

      stateBooking: 'FH',

      allowRetry: 'false',
      showLink: false,

      pdfUrlTerms: null,
      pdfUrlItinerary: null,
      pdfUrlHotelVoucher: null,
      pdfUrlTicket: null,
      pdfUrlReceipt: null,
      pdfUrlReceipts: null,

      orderNo: 'N/A',
      payer: null,
      payerJSON: '',
      price: 0,
      bookedHotel: null,
      flights: {
        outward: {},
        inward: {},
      },
      boolNotFinal: false,

      product: null,
      response: null,

      downDocument: null,

      showWaiting: false,
      waitingTime: 10 * 60 * 1000,

      successfullySentMessage: false,
      sendingMessage: false,
      maccabiErrorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      devMode: 'GET_MODE',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerAgency: 'GET_MARKETER_AGENCY',
      typeChannel: 'GET_TYPE_CHANNEL',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      isMobileApp: 'GET_STATE_MOBILE_APP',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      maccabiUrlPrices: 'GET_MACCABI_VERIFYING_URLS',
    }),
    // bookingInfoPageLink() {
    //   return `${VUE_APP_ADMIN_ENDPOINT}/bookingInfo/${this.bookingDataID}`;
    // },
  },
  watch: {
    isLoading() {
      this.loading = this.isLoading;
    },
    lang: 'updateLabelWithLang',
  },
  async created() {
    this.$emit('setBookingStage', 3);
    window.scrollTo(0, 0);
    const product = JSON.parse(window.sessionStorage.getItem('production'));
    this.product = product;
    this.stateBooking = product.category.packageType;
    const BookingDetailInfo = JSON.parse(
      window.sessionStorage.getItem('BookingDetailInfo'),
    );
    this.payer = {
      orderNo: '',
      status: '',
      name: '',
      mobile: '',
      email: '',
      price: '',
    };
    this.payer = (this.bypassPaymentState) ? BookingDetailInfo.data.paxList[0] : BookingDetailInfo.data.payer;
    this.payer.orderNo = this.orderNo;
    this.payer.status = 'ERROR';
    this.payer.price = this.lang === 'he' ? `( ₪${BookingDetailInfo.data.priceNIS} ) ${this.getPriceWithSymbol(this.product.cc, BookingDetailInfo.data.price)}` : `${this.getPriceWithSymbol(this.product.cc, BookingDetailInfo.data.price)}`;

    if (this.stateBooking === 'FH') {
      const fromDate = dayjs(product.fromDate).add(
          Number(product.hotel_shift_1),
        ),
        toDate = fromDate.add(product.duration_1, 'day');
      const hotels = JSON.parse(window.sessionStorage.getItem('roomList')).roomList;
      this.bookedHotel = {
        packCategCode: product.category.code,
        hotelPrice: 0,
        hotelName: product.hotels?.[0]?.hotelName,
        grade: Number(product.hotels?.[0]?.grade.substr(0, 1)) || 0,
        period: `${fromDate.format('DD/MM/YYYY')}-${toDate.format('DD/MM/YYYY')}`,
        basisCode: product.hotels?.[0]?.basic_fare,
        supplements: product.addSupplement,
        hotels,
        imageList: [],
        translations: null,
      };

      // for (let i = 0; i < product.addSupplement.length; i++) {
      //   this.bookedHotel.transfer += product.translations.suppName[product.addSupplement[i].suppId][this.lang] + " ";
      // }

      /* for (let i = 0; i < hotels.length; i++) {
        this.bookedHotel.hotelPrice += hotels[i].price;
      } */

      const priceNIS = BookingDetailInfo.data.priceNIS !== undefined || BookingDetailInfo.data.priceNIS !== null ? BookingDetailInfo.data.priceNIS : 0,
        priceUSD = BookingDetailInfo.data.price !== undefined || BookingDetailInfo.data.price !== null ? BookingDetailInfo.data.price : 0;
      this.bookedHotel.price = (this.lang === 'he' && !this.isFcAgentMarketerMode) ? `( ₪${priceNIS} ) ${this.getPriceWithSymbol(this.product.cc, priceUSD)}` : `${this.getPriceWithSymbol(this.product.cc, priceUSD)}`;
      this.bookedHotel.hotelPrice = this.bookedHotel.price;

      // this.bookedHotel.price = BookingDetailInfo.data.price;
      // this.bookedHotel.hotelPrice = BookingDetailInfo.data.price;

      this.bookedHotel.imageList = this.bookedHotel.imageList.concat(
        product.perspectiveUrls,
      );
      this.bookedHotel.imageList = this.bookedHotel.imageList.concat(
        product.galleryUrls,
      );
      this.bookedHotel.translations = product.translations;
    }

    const flightID = `${BookingDetailInfo?.data?.outwardFlight?.flightId}${BookingDetailInfo?.data?.inwardFlight?.flightId}`;
    let idxFlight = product.flights.findIndex((flight) => `${flight.fl_id1}${flight.fl_id2}` === flightID);
    if (idxFlight < -1) {
      console.log('error for finding flight');
      idxFlight = 0;
    }
    const outwardFlight = product.flights[idxFlight].FlightDetail[0],
      inwardFlight = product.flights[idxFlight].FlightDetail[1];

    this.flights.outward = {
      airlineName: outwardFlight.arl_Name,
      airlineCode: outwardFlight.airlineInfo.code || outwardFlight.FL_AIRLINE,
      airlineNumber: outwardFlight.FL_Flt_Number,
      airlineID: outwardFlight.FL_ID,
      departureCode: outwardFlight.FL_From_Route,
      departureAddress: outwardFlight.FL_From_Route,
      depature: this.getFullDate(
        outwardFlight.FL_Date,
        outwardFlight.FL_Dep_Hour,
      ),
      arrival: this.getFullDate(
        outwardFlight.landingDate,
        outwardFlight.FL_Arrv_Hour,
      ),
      arrivalCode: outwardFlight.FL_To_Route,
      arrivalAddress: outwardFlight.FL_To_Route,
      including: outwardFlight.FL_Max_Weight,
      confirmed: outwardFlight.Authorization_msg,
    };

    this.flights.inward = {
      airlineName: inwardFlight.arl_Name,
      airlineCode: inwardFlight.airlineInfo.code || inwardFlight.FL_AIRLINE,
      airlineNumber: inwardFlight.FL_Flt_Number,
      airlineID: inwardFlight.FL_ID,
      departureCode: inwardFlight.FL_From_Route,
      departureAddress: inwardFlight.FL_From_Route,
      depature: this.getFullDate(
        inwardFlight.FL_Date,
        inwardFlight.FL_Dep_Hour,
      ),
      arrival: this.getFullDate(
        inwardFlight.landingDate,
        inwardFlight.FL_Arrv_Hour,
      ),
      arrivalCode: inwardFlight.FL_To_Route,
      arrivalAddress: inwardFlight.FL_To_Route,
      including: inwardFlight.FL_Max_Weight,
      confirmed: inwardFlight.Authorization_msg,
    };

    await this.makeOrder();

    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    this.$bvToast.show('waiting');
    // this.makeOrder();

    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
  methods: {
    addAnalytics() {
      // const axel = `${Math.random()}`;
      // const a = axel * 10000000000000;
      // document.write(`<img src='${a}? width="1" height="1" alt=""/>`);
    },
    async makeOrder() {
      let refresh = false;
      const { query } = this.$route;
      const paymentTransUid = query.params;

      const BookingDetailInfo = JSON.parse(
        window.sessionStorage.getItem('BookingDetailInfo'),
      );
      if (!this.bypassPaymentState) {
        BookingDetailInfo.data.payer.IdenticationNumber = query.Tz;
      }
      window.sessionStorage.setItem('BookingDetailInfo', JSON.stringify(BookingDetailInfo));
      // this.$bvToast.show('waiting');

      const { marketerId } = this.$route.query;
      this.$store.commit('SET_MARKETER_ID', marketerId);
      if (marketerId) { await this.$store.dispatch('FETCH_AGENCY_FROM_MARKETER_ID'); }

      const maccabiInfo = { ...this.maccabiUrlPrices };
      const maccabiCardIndex = maccabiInfo.currentIndex || 0;
      const payload = { paymentTransUid, maccabiCardIndex };

      /**
       * If B2B marketer or bypass payment marketer
       */
      if (this.bypassPaymentState) {
        const retryState = JSON.parse(window.sessionStorage.getItem('retryState'));
        payload.retryState = retryState?.readOnly || false;
      }
      let res = await this.$store.dispatch('GET_BOOKED_INFO', payload);

      /**
       *  If B2B marketer or bypass payment marketer
       */
      if (this.bypassPaymentState) {
        window.sessionStorage.setItem('BookingDataID', res.data.bookingTransactionId);
      }

      /**
       * If response has an error, this endpoint retrieve the booking information.
       */

      if (res.data && res.data.error && Number(res.data.error.code) === 1) {
        res = await this.$store.dispatch('GET_SUCCEED_BOOKING');
        refresh = true;
      }

      /**
       * MACCABI agency booking part
       */
      if (this.isMaccabiAgency && res && res.status === 200 && (res.data.paymentSucceeded || res.data.paymentSucceeded === undefined)) {
        if (maccabiCardIndex === 0 && maccabiInfo?.prices?.length > 1 && res.data.paymentSucceeded) {
          /**
           * If debitCreditCard endpoint is success, go to next payment.
           */
          maccabiInfo.currentIndex = maccabiCardIndex + 1;
          this.$store.dispatch('UPDATE_MACCABI_VERIFYING_URL', maccabiInfo);

          const paramMarketerId = marketerId !== '' ? `?marketerId=${marketerId}` : '';
          const paramChannel = this.typeChannel !== '' ? `&channel=${this.typeChannel}` : '';
          const paramFcAgentMode = this.isFcAgentMarketerMode ? '&fc-agent-mode' : '';
          this.$router.push({ path: `/booking/verifying-card/?${paramMarketerId}${paramChannel}${paramFcAgentMode}` });
        } else if (maccabiCardIndex && !res.data.succeeded) {
          /**
           * If debitCreditCard endpoint is failed, it is retry with new payment cr2k url.
           */
          this.maccabiErrorMessage = res.data.errorMsg;
          maccabiInfo.urls[maccabiCardIndex] = res.data.retryPaymentUrl;
          const newMaccabiInfo = { urls: maccabiInfo.urls, prices: maccabiInfo.splitAmounts };
          this.$store.dispatch('UPDATE_MACCABI_VERIFYING_URL', newMaccabiInfo);
          this.$bvModal.show('maccabiErrorModal');
        } else {
          /**
           * If last payment is succeeded retrieve the booking info.
           */
          maccabiInfo.currentIndex = maccabiCardIndex + 1;
          res = await this.$store.dispatch('GET_SUCCEED_BOOKING');
          window.sessionStorage.removeItem('maccabi-info');
        }
      }

      this.response = res;
      /**
       * When failed the booking of new endpoint for the MACCABI agency
       * If not MACCABI agency or the case of first/last endpoint of multiple payment, the flow go to general.
       */
      if (this.isMaccabiAgency && maccabiCardIndex === 0 && !res.data.paymentSucceeded && Number(res.data.error.code) !== 1) {
        await this.maccabiErrorProcessing();
      } else {
        await this.updateLabelWithLang();
      }

      setTimeout(() => {
        if (this.showLink && !refresh) {
          if (this.pdfUrlTerms) this.downloadTickets(this.pdfUrlTerms, 'pdfUrlTerms');
          if (this.pdfUrlItinerary) this.downloadTickets(this.pdfUrlItinerary, 'Itinerary');
          if (this.pdfUrlHotelVoucher) this.downloadTickets(this.pdfUrlHotelVoucher, 'HotelVoucher');
          if (this.pdfUrlTicket) this.downloadTickets(this.pdfUrlTicket, 'Ticket');
          if (this.isMaccabiAgency && this.pdfUrlReceipts && this.pdfUrlReceipts.length) {
            for (let i = 0; i < this.pdfUrlReceipts.length; i += 1) {
              this.downloadTickets(this.pdfUrlReceipts[i], 'Receipt');
            }
          } else if (this.pdfUrlReceipt) this.downloadTickets(this.pdfUrlReceipt, 'Receipt');
        }
      }, 500);

      // this.addAnalytics();

      // if (!window.location.host.includes('vercel') && !refresh && this.response && this.response.status === 200 && !this.response.data.error && [8, 9].includes(this.response.data.status.code)) {
      if (this.availableEmitEventGA4ByDomain() && !refresh) {
        let name = '', listName = '', brand = '', checkInOut = '';
        let discount = 0, numberRoom = 0, nameCategory = '', bookState = '';

        nameCategory = this.product.category.name[this.lang];
        if (res.status.code === 8) {
          bookState = 'Saved Only';
        } else if (res.status.code === 9) {
          bookState = 'Successful Debit';
        }

        if (this.product.category.code !== 'Flight_Only') {
          discount = this.product.discountPercent;
          numberRoom = this.bookedHotel.hotels?.length ?? 0;
          name = this.bookedHotel.hotelName;
          checkInOut = this.bookedHotel.period;
          brand = this.bookedHotel.hotelName;
          listName = '';
        } else {
          discount = this.product.discountPercentFO;
          name = `flight to ${this.product?.translations?.flightDestinationName?.[this.product.destination_1]?.he}`;
          checkInOut = `${this.product.flights[0].FlightDetail[0].FL_Date} - ${this.product.flights[0].FlightDetail[0].landingDate}`;
          brand = this.flights.outward.airlineName;
          listName = '';
        }

        const affiliation = this.marketerId === undefined || this.marketerId === '' ? 'Flying Carpet' : this.marketerAgency;
        const currencyCode = this.convertCurrencyCode(BookingDetailInfo.data?.currency);
        // GA4 gtag
        this.gtag('event', 'purchase', {
          transaction_id: this.response?.data?.pnr ?? '', // RANDON NUMBER
          value: this.response?.data?.totalPayment || 0,
          affiliation,
          currency: currencyCode,
          tax: 0,
          shipping: 0,
          // coupon: 'COUPON',
          items: [
            {
              item_id: this.response?.data?.pnr ?? '', // PNR
              item_name: name,
              affiliation,
              // coupon: "if using coupon please indicare here coupon name",
              index: 0,
              discount,
              item_brand: brand,
              item_category: checkInOut,
              item_category2: numberRoom,
              item_category3: nameCategory,
              item_category4: bookState,
              item_category5: this.response?.data?.pnr ?? '',
              item_list_id: '',
              item_list_name: listName,
              item_variant: '0',
              location_id: '0',
              quantity: 1,
              price: this.response?.data?.totalPayment || 0, // TOTAL SUM

            },
          ],
        });

        // this.gtag('event', 'purchase', {
        //   transaction_id: this.response?.data?.pnr ?? '', // RANDON NUMBER
        //   value: this.response?.data?.totalPayment || 0,
        //   // affiliation: marketerId === undefined || marketerId === '' ? 'Flying Carpet' : this.marketerAgency,
        //   affiliation,
        //   currency: currencyCode,
        //   // currency: 'USD',
        //   tax: 0,
        //   shipping: 0,
        //   items: [
        //     {
        //       id: this.response?.data?.pnr ?? '', // PNR
        //       // affiliation: marketerId === undefined || marketerId === '' ? 'Flying Carpet' : this.marketerAgency,
        //       affiliation,
        //       name, /// DEAL TYPE
        //       list_name: listName, // DESTINATION
        //       brand, // HOTEL NAME OR FLIGHT
        //       category: 'FLYING CHULL', // STATIC
        //       variant: 'ONLINE DEAL', // STATIC
        //       list_position: 1,
        //       quantity: 1,
        //       price: this.response?.data?.totalPayment || 0, // TOTAL SUM
        //     },
        //   ],
        // });
      }

      // const _oneself = this;
      // setTimeout(() => {
      //   _oneself.$bvToast.hide('waiting');
      // }, 200);
    },
    showWaitingToast(pType) {
      if (pType === 'show') {
        const h = this.$createElement;
        const vNodesMsg = h('div', { class: ['dp-flex'] }, [
          h('span', {}, this.$t('booking.waiting-populating')),
          h('b-img', { props: { src: `${this.speedSizeDomain}/assets/img/waiting.gif` } }),
        ]);
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] },
          [h('strong', {}, this.$t('booking.waiting'))],
        );
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          noAutoHide: true,
          ok: false,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
      } else if (pType === 'hide') {
        this.$bvToast.hide();
      }
    },
    async updateLabelWithLang() {
      const { translations } = this.product;
      this.flights.outward.departureAddress = translations?.flightDestinationName[this.flights.outward.departureCode][this.lang] || this.flights.outward?.departureAddress;
      this.flights.inward.departureAddress = translations?.flightDestinationName[this.flights.inward.departureCode][this.lang] || this.flights.inward?.departureAddress;
      this.flights.outward.arrivalAddress = translations?.flightDestinationName[this.flights.outward.arrivalCode][this.lang] || this.flights.outward?.arrivalAddress;
      this.flights.inward.arrivalAddress = translations?.flightDestinationName[this.flights.inward.arrivalCode][this.lang] || this.flights.inward?.arrivalAddress;

      // if need translate for flights.confirmed, please add it here
      if (this.response && this.response.status === 200) {
        const stateBook = this.response.data,
          { error } = stateBook;

        this.payer.price = (this.lang === 'he' && !this.isFcAgentMarketerMode) ? `( ₪${stateBook.totalPaymentInNIS} ) ${this.getPriceWithSymbol(this.product.cc, stateBook.totalPayment)}` : `${this.getPriceWithSymbol(this.product.cc, stateBook.totalPayment)}`;
        if (this.bookedHotel) this.bookedHotel.hotelPrice = this.payer.price;
        // this.showLink = stateBook.paymentSucceeded;
        if (!error) {
          this.showLink = true;
          this.pdfUrlTerms = stateBook.pdfUrlTerms;
          this.pdfUrlItinerary = stateBook.pdfUrlItinerary;
          this.pdfUrlHotelVoucher = stateBook.pdfUrlHotelVoucher;
          this.pdfUrlTicket = stateBook.pdfUrlTicket;
          this.pdfUrlReceipt = stateBook.pdfUrlReceipt;
          this.pdfUrlReceipts = stateBook.pdfUrlReceipts;

          this.variantClass = 'success';
          this.header = this.variant.toUpperCase();
          this.message = stateBook.messageToVisitor[this.lang];
          this.orderNo = stateBook.pnr;
          this.payer.orderNo = this.orderNo;

          if (stateBook.paymentSucceeded) {
            this.variant = this.$t('booking.success');
            this.payer.status = 'OK';
            // this.payer.price = stateBook.totalPayment;
            // if (this.bookedHotel) this.bookedHotel.hotelPrice = stateBook.totalPayment;

            this.allowRetry = false;
            window.sessionStorage.setItem('retryState', null);
          } else if (stateBook.status.code === 8) {
            this.variant = this.$t('booking.warning');
            // this.message = error.message[this.lang] || error.message.en;
            // if (this.devMode) this.showLink = true;
            this.payer.status = 'RQ';
            // this.payer.price = stateBook.totalPayment;
            // if (this.bookedHotel) this.bookedHotel.hotelPrice = stateBook.totalPayment;

            this.allowRetry = false;
            window.sessionStorage.setItem('retryState', null);
          }
          // testing retry
          // this.allowRetry = true;
          // window.sessionStorage.setItem('retryState', { readOnly: true });
        } else if (error) {
          if (stateBook.status.code === -3 || stateBook.status.code === -4 || stateBook.status.code === -6 || stateBook.status.code === -7) {
            this.payer.status = 'REFUSE';
          } else {
            this.payer.status = 'ERROR';
          }
          this.showLink = false;
          this.variant = this.$t('booking.danger');
          this.header = this.$t('booking.error');
          this.variantClass = 'warning';
          this.message = error.message[this.lang] || error.message.en;
          this.orderNo = stateBook.pnr || 'N/A';
          this.payer.orderNo = this.orderNo;
          // this.payer.price = stateBook.totalPayment;

          // if (this.bookedHotel) this.bookedHotel.hotelPrice = stateBook.totalPayment;
          const retryState = {
            readOnly: error.retryPaymentMethodOnly,
          };
          window.sessionStorage.setItem('retryState', JSON.stringify(retryState));
          if (error.retryAllowed) {
            this.allowRetry = true;
            // if(error.retryPaymentMethodOnly ){
            //     window.removeEventListener('beforeunload', this.ClosingWindow);
            //     window.history.go(-4);
            // }
          } else {
            this.allowRetry = false;
          }
        }

        this.payer = JSON.parse(JSON.stringify(this.payer));
      } else {
        this.header = this.$t('booking.error');
        this.variant = this.$t('booking.danger');
        // this.message = this.response.error.message;
        this.message = this.$t('booking.error');
        this.variantClass = 'danger';
        this.allowRetry = false;
        // this.allowRetry = true;
        // let retryState = {
        //     readOnly: false,
        // };
        // window.sessionStorage.setItem("retryState", JSON.stringify(retryState));
      }
    },
    async maccabiErrorProcessing() {
      const { data } = this.response;

      this.showLink = false;
      this.variant = this.$t('booking.danger');
      this.header = this.$t('booking.error');
      this.variantClass = 'warning';
      this.message = data.errorMsg;
      this.orderNo = 'N/A';
      this.payer.orderNo = this.orderNo;

      this.allowRetry = data.retryPaymentUrl !== null;
    },
    goToDebit() {
      // window.removeEventListener('beforeunload', this.ClosingWindow);

      const signChannel = this.$route.path.includes('/web') ? 'web' : 'oldapp';
      const info = JSON.parse(window.sessionStorage.getItem('production'));
      let body = {};
      if (this.stateBooking === 'FH') {
        body = {
          packID: info.packId,
          laID: info.hotels[0].La_ID,
          flights: info.outwardFlight.flightId + info.inwardFlight.flightId,
        };
        this.$router.push({ path: `/booking/hotel-flight/${signChannel}`, query: body });
      } else if (this.stateBooking === 'F') {
        body = {
          packID: info.packId,
          flights: info.outwardFlight.flightId + info.inwardFlight.flightId,
        };
        this.$router.push({ path: `/booking/flight/${signChannel}`, query: body });
      }
    },
    async ClosingWindow() {
      const bookingDataID = this.bookingDataID || window.sessionStorage.getItem('BookingDataID');
      await this.$store.dispatch('SEND_CLOSING_NOTICE', {
        bookDataID: bookingDataID,
        withData: false,
      });
    },
    getFullDate(pDate, pTime) {
      const data = { dateTime: '', notFinal: false };
      if (pTime === 'NOT_FINAL') {
        data.dateTime = dayjs(pDate).format('DD/MM/YYYY');
        data.notFinal = true;
      } else {
        data.dateTime = dayjs(`${pDate} ${pTime}`).format('DD/MM/YYYY, hh:mm a');
        data.notFinal = false;
      }
      return data;
    },
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 30 * 1000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
          // window event not working here
        } else {
          // const evt = new MouseEvent('click', {
          //   view: window,
          //   bubbles: true,
          //   cancelable: false,
          // });
          const evt = new MouseEvent('click');
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
    handleRatingApp() {
      window.location = 'https://app.bonauf.co.il/assets/rating.html#success';
    },
    async sendMessage() {
      this.sendingMessage = true;
      const response = await this.$store.dispatch('SEND_MESSAGE_TO_CLIENT');
      this.sendingMessage = false;
      let message = '';
      if (response === 'error') {
        this.successfullySentMessage = false;
        message = 'error';
      } else {
        this.successfullySentMessage = true;
        message = response;
      }
      this.$bvToast.toast(message, {
        title: 'Confirm',
        solid: true,
        noAutoHide: true,
        ok: false,
        bodyClass: 'ltr',
      });
    },
    gotoRetryForMaccabi() {
      const { marketerId } = this.$route.query;
      const paramMarketerId = marketerId !== '' ? `?marketerId=${marketerId}` : '';
      const paramChannel = this.typeChannel !== '' ? `&channel=${this.typeChannel}` : '';
      const paramFcAgentMode = this.isFcAgentMarketerMode ? '&fc-agent-mode' : '';
      this.$router.push({ path: `/booking/verifying-card/?${paramMarketerId}${paramChannel}${paramFcAgentMode}` });
    },
  },
};
</script>

<style>
#waiting.ltr {
  direction: ltr;
}
#waiting.rtl {
  direction: rtl;
}
#waiting h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#waiting img {
  width: 100px;
}
#waiting h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
</style>

<style scoped>
.card-deck .card {
  margin-bottom: 25px;
}
button.backDebit {
  background: #0061ab;
  color: #fff;
  margin: 10px 10px;
}

h1 {
  font-size: 45px;
  font-weight: 400;
  color: #0e436b;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.after_booking_area .order_no_box {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px;
  margin-bottom: 25px;
}
.after_booking_area .order_no_box .order_no_div {
  width: 100%;
  text-align: left;
}
.after_booking_area .order_no_box .order_no_div h2 {
  margin: 0 0;
  padding: 6px 0;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
}
.after_booking_area .order_no_box .order_no_div h2 strong.order_no {
  color: #79bff5;
}
.after_booking_area .order_no_box .order_no_div p {
  margin: 0 0;
  color: #fff;
  font-size: 15px;
}
.after_booking_area .order_no_box .order_no_div p a {
  text-decoration: underline;
  color: #fff;
  transition: 0.5s;
}
.after_booking_area .order_no_box .order_no_div p a:hover {
  text-decoration: none;
}

.please_mall_area {
  margin-bottom: 25px;
}
.please_mall_area .plase_mall_contain_box {
  border: 1px solid #949494;
}
.please_mall_area .plase_mall_contain_box h3 {
  padding: 20px 20px;
  border-bottom: 1px solid #949494;
  font-size: 24px;
  text-transform: uppercase;
}
.please_mall_area .plase_mall_contain_box h3 span {
  color: #0763ab;
}
.please_mall_area .please_mall_contain_body {
  padding: 30px 20px;
}
.please_mall_contain_body .please_mall {
  width: 100%;
  text-align: center;
}
.please_mall_contain_body .please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 15px;
}
.please_mall_contain_body .please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
}
.please_mall_area .img_box {
  height: 275px;
  overflow: hidden;
  border-radius: 5px;
}

#btnSubmit {
  color: #fff;
  background-color: #0061ab;
  border-color: #0061ab;
  font-size: 25px;
  font-weight: bold;
}

.icon_box img {
  width: 100%;
  height: auto;
}

@media (min-width: 991.98px) and (max-width: 1200px) {
  .please_mall_contain_body .please_mall .icon_box {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 1200px) {
  .please_mall_area .img_box img {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .after_booking_area .order_no_box {
    width: 100%;
    height: auto;
    background-position: right;
    padding: 5px 20px;
    margin-bottom: 15px;
  }
  .order_no_box .d-flex {
    display: block !important;
  }
  .after_booking_area .order_no_box .order_no_div p {
    font-size: 12px;
  }
  .after_booking_area .order_no_box .order_no_div h2 {
    font-size: 16px;
  }
  .please_mall_area .img_box {
    height: auto;
  }
  .please_mall_area .plase_mall_contain_box h3 {
    padding: 14px 13px;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
  }
  .please_mall_area .please_mall_contain_body {
    padding: 0px 10px 15px;
  }
  .please_mall_area .please_mall_contain_body .d-flex {
    display: block !important;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    margin-top: 15px;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    width: 100%;
    text-align: center;
  }
}
</style>
