<template>
  <div class="apparea deal-filter">
    <div v-if="!showMenu" class="deals">
      <filter-option-panel
        :resetOption="resetOption"
        :open="openFilterPanel"
        @emitMessage="receiveMessage"
      />
      <div class="filter-cont dVacation-pack" >
        <div v-if="isLoading" class="d-flex loading">
          <img class="m-auto" src="/assets/img/loading1.gif" alt="Loading..." />
        </div>
        <div v-else>
          <app-deal-menu-picker
            :pickCodeCategory="pickCodeCategory"
            :pickCodeSubcategory="pickCodeSubcategory"
            @emitMessage='receiveMessage'
          />
          <div class="p-2 bg-white">
            <app-deal-batch
              v-for="(batch, index) in sortedDealsPerHotel"
              :key="index"
              :batch="batch"
              :batchIndex="index"
              :forceHideExtraDeals="index !== shownExtraDealsIndex"
              :hideHeaderForSingleDeal="pickCodeCategory === '41990'"
              @emitMessage="receiveMessage"
            />
          </div>
        </div>
        <footer-mobile />
      </div>
    </div>
    <menu-deal-category v-else
      @emitMessage="receiveMessage"
    />
    <b-modal id="share-this-modal" v-model="openShareModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareContent"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import TemplateDealFilter from './TemplateDealFilter';

export default {
  name: 'DealFilterApp',
  components: {
    FilterOptionPanel: () => import('@/components/bonauf/atoms/FilterOptionPanel/FilterOptionPanelMobile'),
    AppDealMenuPicker: () => import('@/components/bonauf/atoms/AppDealMenuPicker'),
    MenuDealCategory: () => import('@/components/bonauf/filterDeal/MenuDealCategory'),
    AppDealBatch: () => import('@/components/bonauf/atoms/AppDealBatch'),
    FooterMobile: () => import('@/components/bonauf/atoms/FooterMobile'),
    BModal,
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  extends: TemplateDealFilter,
};
</script>

<style scoped>
.loading {
  margin-top: 200px;
}
</style>
