<template>
  <div class="apparea">
    <section class="flight_only_area vh-100" v-if="isLoading">
      <ContentLoading type="product" />
    </section>
    <section class="flight_only_area" v-else>
      <!----- header area start ----->
      <header-desktop />
      <!----- header area stop ----->
      <main class="body_warrper" ref="flight">
        <!------ plaindetails area start ------->
        <h3 class="py-4 text-center"> פרטי הטיסה</h3>
        <div v-if="!availableFlight">
          <section class="plaindetails-area">
            <div class="container">
              <div class="plaindetails-box">
                <h5>{{ $t("flight-only.unavailable-flight") }}</h5>
              </div>
            </div>
          </section>
        </div>
        <flight-box-one-app type="depature" :data="departureItem" :airInfo="airInfoDepart"/>
        <flight-box-one-app type="return" :data="returnItem" :airInfo="airInfoReturn" />
        <!------ plaindetails area stop ------->
        <section class="plaindetails-area">
          <div class="container" v-if="extraFlights.length>0">
            <div class="select-flight d-flex"  v-if="extraFlights.length>1">
              <div class="selectflhtbtn m-auto btn-primary" id="selectflhtbtn" v-b-toggle="`filter-content`">
                <img :src="`${speedSizeDomain}/assets/img/flight.png`" alt="" />{{ $t('product-page.more-flight-option') }}
              </div>
            </div>
            <b-collapse class="filter-cont dVacation-pack" :id="`filter-content`" v-model="visibleMoreFlights">
              <flight-item-app
                v-for="(item, inx) in extraFlights"
                :key="inx"
                :flight="item"
                :idx="inx"
                :selectIdx="idxFlight"
                categoryName="Flight_Only"
                type="product"
                @change="changeFlightIndex"
              />
            </b-collapse>
          </div>
        </section>
        <!------ payment area start ------>
        <section class="paymentbox-area">
            <div class="container">
                <h6>{{ $t("booking.payment") }}</h6>
            </div>
        </section>
        <!------ payment area stop ------>
        <flight-box-three-app :product="product" :airInfo="airInfoDepart"/>
      </main>
      <footer-desktop :backLink="backLink"/>
    </section>
  </div>
</template>

<script>
import { VBToggle, BCollapse } from 'bootstrap-vue';
import TemplateFlightOnly from './TemplateFlightOnly';

export default {
  name: 'FlightOnlyAppDesktop',
  components: {
    // BCollapse,    
    HeaderDesktop: () => import('@/components/bonauf/atoms/HeaderDesktop'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    FlightBoxOneApp: () => import('@/components/bookingFlightOnly/FlightBoxOneApp'),
    FlightBoxThreeApp: () => import('@/components/bookingFlightOnly/FlightBoxThreeApp'),
    FlightItemApp: () => import('@/components/productPage/atoms/FlightItemApp'),
    BCollapse,
    FooterDesktop: () => import('@/components/bonauf/atoms/FooterDesktop'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  extends: TemplateFlightOnly,
  data() {
    return {
      backLink: '',
    }
  },
  created() {
    const dealId = window.sessionStorage.getItem('dealId');
    this.backLink = `/deal-tab/app-desktop?id=${dealId}&channel=App`;
  },
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
    setFlightOfProduct() {
      this.product.discounted = 0;
      this.product.discountPercent = 0;
      this.product.category = this.category;

      const activeFlight = this.extraFlights[this.idxFlight];
      if (!activeFlight) return;
      const OFObject = activeFlight.FlightDetail[0], IFObject = activeFlight.FlightDetail[1];
      const outwardFlight = {
          flightId: OFObject.FL_ID,
          departureCityCode: OFObject.FL_From_Air_Port,
          departureDate: OFObject.FL_Date,
          departureTime: OFObject.FL_Dep_Hour,
          arrivalCity: OFObject.FL_To_Air_Port,
          class: activeFlight.Class1,
          paxIds: [],
        },
        inwardFlight = {
          flightId: IFObject.FL_ID,
          departureCityCode: IFObject.FL_From_Air_Port,
          departureDate: IFObject.FL_Date,
          departureTime: IFObject.FL_Dep_Hour,
          arrivalCity: IFObject.FL_To_Air_Port,
          class: activeFlight.Class2,
          paxIds: [],
        };
      this.product.outwardFlight = outwardFlight;
      this.product.inwardFlight = inwardFlight;

      let barCode = activeFlight.FlightDetail[0].FL_AIRLINE;
      if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoDepart = this.product.airlineInfo[barCode];

      barCode = activeFlight.FlightDetail[1].FL_AIRLINE;
      if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoReturn = this.product.airlineInfo[barCode];

      activeFlight.FlightDetail[0].airlineInfo = this.airInfoDepart;
      activeFlight.FlightDetail[1].airlineInfo = this.airInfoReturn;
      this.product.flights = [activeFlight];
      // this.product.packSelectionId = this.packSelectionId;

      this.product.flights.forEach((itemFlight) => {
        itemFlight.translations = this.product.translations;
      });

      this.departureItem = activeFlight.FlightDetail[0];
      this.departureItem.translations = this.product.translations;
      this.returnItem = activeFlight.FlightDetail[1];
      this.returnItem.translations = this.product.translations;

      window.sessionStorage.setItem('production', JSON.stringify(this.product));
    },
    gotoDealTabPage() {
      const dealId = window.sessionStorage.getItem('dealId');
      this.$router.push(`/deal-tab/app?id=${dealId}&channel=App`);
    },
  },
};
</script>

<style lang="less" scoped>
  .productPage .btnDomestic {display:none !important;}

  .bonauf-desktop {

    .apparea {
      max-width: 100% !important;

      .flight_only_area {
        padding-bottom: 140px;
      }

      .body_warrper {
        .plaindetails-area {
          
          #filter-content {
            max-width: 730px;
            margin: auto;
            z-index: 1;
            position: relative;
          }
        }
        
        .plaindetails-area:before {
          height: 20%;
        }
        h3 {
          font-family: 'FbCoherentiSansBold';
          font-size: 28px;
          margin-bottom: 0;
        }
      }
      .body_warrper:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 170px;
        z-index: -1;
        background: rgb(3,148,208);
        background: linear-gradient(90deg, rgba(3,148,208,1) 0%, rgba(132,219,255,1) 100%);
      }
    }
  }
</style>
