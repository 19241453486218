<template>
  <div>
    Template Deal Filter Page
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateDealFilter',
  mixins: [imageUrlMixin],
  data() {
    return {
      showMenu: false,
      resetOption: false,
      openFilterPanel: false,
      pickCodeCategory: '',
      pickCodeSubcategory: '',
      filterOption: {
        destination: null,
        category: null,
        month: null,
        fromPrice: null,
        toPrice: null,
      },
      openShareModal: false,
      shareContent: null,
      shownExtraDealsIndex: -1,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      sortedDealsPerHotel: 'GET_SORTED_DEALS_PER_HOTEL',
    }),
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'deal-filter');
    await this.fetchAssetsData();
  },
  mounted() {
  },
  methods: {
    receiveMessage(pMsg) {
      const { kind, data } = pMsg;
      switch (kind) {
        case 'toggleMenu':
          this.showMenu = !this.showMenu;
          break;
        case 'pickCategory':
          this.resetOption = true;
          setTimeout(() => {
            this.pickCodeCategory = data.code;
            this.pickCodeSubcategory = '';
            this.filterOption = {
              destination: null,
              category: data.code,
              month: null,
              fromPrice: null,
              toPrice: null,
            };
            this.$store.commit('SET_FILTER_OPTION', this.filterOption);
            this.pickCodeSubcategory = '';
            this.resetOption = false;
          }, 100);
          break;
        case 'pickFilterCategory':
          this.pickCodeCategory = data.code;
          this.pickCodeSubcategory = '';
          this.filterOption.category = data.code;
          this.$store.commit('SET_FILTER_OPTION', this.filterOption);
          this.shownExtraDealsIndex = -1;
          break;
        case 'pickSubcategory':
          this.resetOption = true;
          this.pickCodeCategory = data.codeCategory;
          this.pickCodeSubcategory = data.codeSubcategory;
          this.shownExtraDealsIndex = -1;
          setTimeout(() => {
            this.openFilterPanel = false;
            this.$store.commit('SET_APP_DEAL_CATEGORY_OPTIONS', data);
          }, 10);
          break;
        case 'updatedFilterOption':
          this.pickCodeCategory = data.category;
          this.pickCodeSubcategory = '';
          this.openFilterPanel = true;
          this.resetOption = false;
          this.filterOption = data;
          this.$store.commit('SET_FILTER_OPTION', data);
          break;
        case 'showShareModal':
          this.shareContent = data;
          this.openShareModal = !!data;
          break;
        case 'shownExtraDeals':
          this.shownExtraDealsIndex = data.index;
          break;
        default:
          console.log(`please handle the ${kind} action`);
      }
    },
    async fetchAssetsData() {
      this.$store.dispatch('FETCH_APP_DEAL_MENU');
      this.$store.dispatch('FETCH_APP_DEAL_SUBCATEGORY');
      this.$store.dispatch('FETCH_APP_DEAL_PACKAGES');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
